
import { call, put } from "redux-saga/effects";
import { updateUser, authenticateUser } from "../store/user";
import { API_URLS } from "../resources/constants";
import {CallApi} from './callApi'

export function* login(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.LOGIN, payload);
    console.log('login result: ', result)
    // yield put(updateUser(result.data));
    // action && action.callbackSuccess(result.data);
    if ( result['data'] && result['data']['success'] ) {
      yield put(updateUser(result.data.data));
      yield put(authenticateUser(true));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* forgetPassword(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.FORGET_PASSWORD, payload);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* forgetPasswordReset(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.FORGET_PASSWORD_RESET, payload);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}
export function* sendEmailVerification(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.USER.RESEND_EMAIL_VERIFICATION, payload);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* fetchUserByEmail(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.USER.FETCH_USER_BY_EMAIL, payload);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}



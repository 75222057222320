
import { FC, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface Props {
    isShow: boolean;
    title: string;
    children: ReactNode,
    showFooter: boolean,
    handleClose: () => void;
    footerButtonYes?: boolean,
    footerButtonYesText?: string,
    footerButtonYesAction?: () => void,
    footerButtonYesClass?: string,
    footerButtonNo?: boolean,
    footerButtonNoText?: string,
    footerButtonNoAction?: () => void,
    footerButtonNoClass?: string,
    showLoading?: boolean
}

const CustomModal: FC<Props> = ({
    isShow, 
    title, 
    children, 
    showFooter, 
    handleClose,
    footerButtonYes = false,
    footerButtonYesText = '',
    footerButtonYesAction = () => {},
    footerButtonYesClass = '',
    footerButtonNo = false,
    footerButtonNoText = '',
    footerButtonNoAction = () => {},
    footerButtonNoClass = '',
    showLoading = false
}) => {
    return (
        <Modal show={isShow} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {
                showFooter && (
                    <Modal.Footer>
                        {
                            footerButtonYes && (
                                <button type='button' className={footerButtonYesClass} onClick={footerButtonYesAction}>
                                    { footerButtonYesText }
                                </button>
                            ) || ''
                        }
                        &nbsp;
                        {
                            footerButtonNo && (
                                <button type='button' className={footerButtonNoClass} onClick={footerButtonNoAction} disabled={showLoading}>
                                    { showLoading ? <Spinner animation="border" size="sm" /> : footerButtonNoText }
                                </button>
                            ) || ''
                        }
                    </Modal.Footer>
                ) || ''
            }
        </Modal>
    )
}

export default CustomModal;
import React, { useEffect } from "react";
import './Search.scss';
import { CiSearch } from "react-icons/ci";
import { Controller, useForm } from "react-hook-form";
interface props {
    search?: string
    setSearch: (txt: string) => void
}
const Search = ({ search = '', setSearch }: props) => {

    const { control, formState: { errors }, handleSubmit, reset } = useForm();

    const submitHandler = (data: any) => {
        setSearch(data.filter)
    }

    useEffect(() => {
        reset({ filter: search })
    }, [search])

    
    return (
        <form className="app-search p-0" onSubmit={handleSubmit(submitHandler)}>
            <div className="position-relative d-flex">
                <Controller
                    defaultValue={""}
                    control={control}
                    name="filter"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <input autoComplete="new-password" type="text" name={name} onChange={onChange} value={value} className="form-control" placeholder="Search..." />
                    )}
                />
                <CiSearch />
                <button type="submit" className="search-btn btn btn-primary"><CiSearch /></button>
            </div>
        </form>
    )
}

export default Search;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TableDataI } from './types'

export const initialState: TableDataI = {
  date_time: "",
  records: []
}

const tableDataSlice = createSlice({
  name: 'tableData',
  initialState,
  reducers: {
    updateTableData(state, action: PayloadAction<any>) {
      state.date_time = action.payload.date_time
      state.records = action.payload.tabledata
    },
  },
})

export const { reducer } = tableDataSlice

export const { updateTableData } = tableDataSlice.actions

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppLoaderI } from './types'

export const initialState: AppLoaderI = {
  isLoading: false,
  message: 'Loading'
}

const appLoaderSlice = createSlice({
  name: 'appLoader',
  initialState,
  reducers: {
    updateLoader(state: AppLoaderI, action: PayloadAction<any>) {
      state.isLoading = action.payload['isLoading']
      state.message = action.payload['message']
    }
  },
})

export const { reducer } = appLoaderSlice

export const { updateLoader } = appLoaderSlice.actions

import { createContext, useContext, useState, useMemo, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { getUser, getAuthenticated } from '../../store/user/selectors'
import { useLocalStorage } from "../../resources/hooks/localStorageHook";

interface ContextI {
    isAuthenticated: boolean,
    user: any,
    login: (data: any) => void,
    logout: () => void
}
export const AuthContext = createContext<ContextI | null>(null);

interface PropsI {
    children?: ReactNode
    // any props that come into the component
}
export const AuthProvider = ({ children }: PropsI) => {

    const [userLocalStorage, setUserLocalStorage] = useLocalStorage("user", "");
    const userDetails = useSelector(getUser);
    const checkAuthenticated = useSelector(getAuthenticated);

    const authChecker = () => {  
        return (checkAuthenticated && user)?true:false
    }

    const [isAuthenticated, setAuthenticated] = useState(authChecker())
    const [user, setUser] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
      setAuthenticated(authChecker())
      setUser(userDetails)
    }, [userDetails]);

   

    // call this function when you want to authenticate the user
    const login = async (data: any) => {
        setUser(data);
        navigate("/dashboard");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        setUserLocalStorage("")
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            isAuthenticated,
            user,
            login,
            logout
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useCategoryApi() {

    const callApi = useApiCall();

    const list = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CATEGORY.LIST, data, onSuccess, onError);
    }
    const addCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CATEGORY.ADD, data, onSuccess, onError);
    }
    const updateCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CATEGORY.UPDATE, data, onSuccess, onError);
    }
    const deleteCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CATEGORY.DELETE, data, onSuccess, onError);
    }

    const addSubCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SUB_CATEGORY.ADD, data, onSuccess, onError);
    }
    const updateSubCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SUB_CATEGORY.UPDATE, data, onSuccess, onError);
    }
    const deleteSubCat = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SUB_CATEGORY.DELETE, data, onSuccess, onError);
    }


    return {
        list,
        addCat,
        updateCat,
        deleteCat,
        addSubCat,
        updateSubCat,
        deleteSubCat
    }
}
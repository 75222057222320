import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SolutionProviderI } from './types'

export const initialState: SolutionProviderI = {
  details: {},
  list: []
}

const solutionProviderSlice = createSlice({
  name: 'solutionProvider',
  initialState,
  reducers: {
    updateDetails(state: SolutionProviderI, action: PayloadAction<any>) {
      state.details = action.payload
    },
    updateList(state: SolutionProviderI, action: PayloadAction<any>) {
      state = {
        ...state,
        list: action.payload
      }
    },
  },
})

export const { reducer } = solutionProviderSlice

export const { updateDetails, updateList } = solutionProviderSlice.actions

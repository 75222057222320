import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SettingsI } from './types'

export const initialState: SettingsI = {
  date_time: "",
  logo: ''
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(state, action: PayloadAction<any>) {
      state.date_time = action.payload.date_time
      state.logo = action.payload.logo
    },
  },
})

export const { reducer } = settingsSlice

export const { updateSettings } = settingsSlice.actions

import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook'
import { useUserApi } from '../../../resources/hooks/api/userApiHook'
import { CustomPagination } from '../../../components/CustomPagination';
import CustomModal from '../../../components/CustomModal';
import AddUpdateSolutionProvider from './AddUpdate';
import { USER_ROLE } from '../../../resources/constants';
import { getUser } from '../../../store/user/selectors';
import TitleBar from '../../../components/TitleBar';
import Search from '../../../components/search/Search';
import { getSortIcon } from '../../../resources/utils/functions';
import UpdateRole from '../roleUpdate';
import { LuUserCog } from 'react-icons/lu';

interface Props {

}

interface PaginationI {
    page: number,
    total: number
}
const UtilityUserList: React.FC<Props> = () => {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const [selectedUtilityUser, setSelectedUtilityUser] = useState<any | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const loggedUser = useSelector(getUser);
    console.log('====loggedUser', loggedUser)

    const [utilityUsers, setUtilityUsers] = useState<any>({
        data: [],
        total: 0
    });
    const [pagination, setPagination] = useState<PaginationI>({
        page: 1,
        total: 10
    })

    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('');

    const { list: utiityListCall, remove: removeUser, userApproval, resendEmailVerification } = useUserApi();

    useEffect(() => {
        fetchUtilityUsers();
    }, [pagination, search, sort, sortBy])

    const fetchUtilityUsers = () => {
        const params = {
            UserType: USER_ROLE.USER,
            PageNumber: pagination.page,
            PageSize: pagination.total,
            SearchText: search,
            SortOrder: sort,
            SortColumn: sortBy
        }
        showLoader();
        utiityListCall(params, (message: string, resp: any) => {
            hideLoader();
            setUtilityUsers({
                data: resp['data']['objListUser'],
                total: resp['data']['totalRecords']
            })
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handleUserDelete = (provider: any) => {
        const params = {
            userId: provider['userId']
        }
        showLoader();
        removeUser(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            fetchUtilityUsers();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handleUserApproval = (provider: any, makeApproved: boolean) => {
        const params = {
            userId: provider['userId'],
            isApproved: makeApproved
        }
        showLoader();
        userApproval(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            fetchUtilityUsers();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handlePageChange = (currentPage: number) => {
        setPagination({
            ...pagination,
            page: currentPage
        })
    }

    const addProvider = () => {
        setSelectedUtilityUser(null)
        setShowModal(true);
    }

    const editProvider = (provider: any) => {
        setSelectedUtilityUser(provider)
        setShowModal(true);
    }
    const editRoleProvider = (provider: any) => {
        setSelectedUtilityUser(provider)
        setShowRoleModal(true);
    }

    const deleteUser = (user: any) => {
        // setSelectedProvider(provider);
        // setShowConfirmModal(true);
        Swal.fire({
            title: `Are you sure to delete the user, ${user['firstName']} ${user['lastName']}?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleUserDelete(user)
            }
        })
    }

    const approvalUser = (user: any, makeApproved: any) => {
        Swal.fire({
            title: makeApproved == 1 ? `Do you want to approve ${user['firstName']} ${user['lastName']}?` : `Do you want to reject ${user['firstName']} ${user['lastName']}?`,
            showDenyButton: true,
            confirmButtonText: makeApproved == 1 ? 'Yes, approve it' : 'Yes, reject it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleUserApproval(user, makeApproved)
            }
        })
    }

    const closeModal = () => {
        setShowModal(false);
        setShowConfirmModal(false);
        setSelectedUtilityUser(null)
    }
    const closeRoleModal = () => {
        setShowRoleModal(false);
        setShowConfirmModal(false);
        setSelectedUtilityUser(null)
    }

    

    const handleResendEmailVerification = (provider: any) => {
        const params = {
            userId: provider['userId'],
            sentBy: loggedUser.userId,
            emailVerificationBaseURL: process.env.REACT_APP_FRONTEND_BASE_URL + '/email-verification',
        }
        showLoader();
        resendEmailVerification(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const resendEmailVerificationFn = (provider: any) => {
        Swal.fire({
            title: `Are you sure you want to resend email verification mail?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, send it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleResendEmailVerification(provider)
            }
        })
    }


    const sortDataHandler = (sortByField: string) => {
        if (sortBy !== sortByField) {
            setSort('asc')
            setSortBy(sortByField)
        } else {
            setSort((sort == '') ? 'asc' : ((sort == 'asc') ? 'desc' : ''))
        }
    }

    const sortIconHandler = (sortByField: string) => {
        return getSortIcon(sort, sortBy, sortByField)
    }

    return (
        <div>
            <div className="page-content">
                <div className="container-fluid">

                    <TitleBar title={'Utility Users'} breadcrumbs={{
                        'Utility Users': '#',
                    }} />


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='text-right mb-3 d-flex justify-content-between'>

                                        <Search setSearch={(str: string) => {
                                            setSearch(str)
                                            setUtilityUsers({ data: [], total: 0 })
                                            setPagination({ ...pagination, page: 1 })
                                        }} />
                                        <button type='button' className='btn btn11 btn-primary' onClick={() => addProvider()}>Add Utility User</button>
                                    </div>
                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered dt-responsive nowrap no-footer dtr-inline" style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('name')}>
                                                            Full Name
                                                            {sortIconHandler('name')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('email')}>
                                                            Email
                                                            {sortIconHandler('email')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('jobtitle')}>
                                                            Job Title
                                                            {sortIconHandler('jobtitle')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('company')}>
                                                            Company
                                                            {sortIconHandler('company')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        {/* <span className='cursor-pointer' onClick={() => sortDataHandler('workFor')}> */}
                                                        Work For
                                                        {/* {sortIconHandler('workFor')} */}
                                                        {/* </span> */}
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('status')}>
                                                            Status
                                                            {sortIconHandler('status')}
                                                        </span>
                                                    </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    utilityUsers.data.map((provider: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={`/utility-user/${provider['userProfileId']}`}>
                                                                    {provider['firstName'] + ' ' + provider['lastName']}
                                                                </Link>
                                                            </td>
                                                            <td>{provider['email']}</td>
                                                            <td>{provider['jobTitle']}</td>
                                                            <td>{provider['companyName']}</td>
                                                            <td>{provider['workFor'] == USER_ROLE.SOLUTION_PROVIDER ? 'Solution Provider' : provider['workFor'] == USER_ROLE.USER ? 'Utility' : 'Other'}</td>
                                                            <td>
                                                                <div className="d-flex gap-10 action-btns">
                                                                    {
                                                                        (provider?.isApproved == 0) &&
                                                                        <>
                                                                            <NavLink title='Approve' to="javascript:void(0)" className="view" onClick={() => approvalUser(provider, 1)}>
                                                                                <i data-icon="S" className="icon"></i>
                                                                            </NavLink>
                                                                            <NavLink title='Reject' to="javascript:void(0)" className="delete" onClick={() => approvalUser(provider, 2)}>
                                                                                <i data-icon="9" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (provider?.isApproved == 1) &&
                                                                        <>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span className='badge badge-success'> Approved</span>
                                                                            </div>
                                                                            <NavLink title='Reject' to="javascript:void(0)" className="delete" onClick={() => approvalUser(provider, 2)}>
                                                                                <i data-icon="9" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (provider?.isApproved == 2) &&
                                                                        <>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span className='badge badge-danger'>  Rejected</span>
                                                                            </div>
                                                                            <NavLink title='Approve' to="javascript:void(0)" className="view" onClick={() => approvalUser(provider, 1)}>
                                                                                <i data-icon="S" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-10 action-btns">
                                                                    <NavLink
                                                                        title='Edit'
                                                                        to="javascript:void(0)" className="edit" onClick={() => editProvider(provider)}>
                                                                        <i className="dripicons-pencil"></i>
                                                                    </NavLink>
                                                                    <NavLink
                                                                        title='Edit'
                                                                        to="javascript:void(0)" className="edit" onClick={() => editRoleProvider(provider)}>
                                                                        <LuUserCog className='text-white' />
                                                                    </NavLink>
                                                                    <NavLink
                                                                        title='Delete'
                                                                        to="javascript:void(0)" className="delete" onClick={() => deleteUser(provider)}>
                                                                        <i className="dripicons-trash"></i>
                                                                    </NavLink>
                                                                    {/* {
                                                                        provider?.isApproved && <NavLink title='Disapprove' to="javascript:void(0)" className="delete" onClick={() => approvalUser(provider, false)}>
                                                                            <i data-icon="9" className="icon"></i>
                                                                        </NavLink> ||
                                                                        <NavLink
                                                                            title='Approve'
                                                                            to="javascript:void(0)" className="delete" onClick={() => approvalUser(provider, true)}>
                                                                            <i data-icon="S" className="icon"></i>
                                                                        </NavLink>
                                                                    } */}

                                                                    <NavLink title='Send Email Verification'
                                                                        to="javascript:void(0)" className="edit" onClick={() => resendEmailVerificationFn(provider)}>
                                                                        <i className="dripicons-arrow-up"></i>
                                                                    </NavLink>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            utilityUsers.total > 1 &&
                                            <CustomPagination
                                                className='float-right'
                                                totalItems={utilityUsers.total}
                                                itemsPerPage={pagination.total}
                                                currentPage={pagination.page}
                                                onPageChange={handlePageChange}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                showModal && (
                    <CustomModal isShow={showModal} title={selectedUtilityUser ? 'Update Utility User' : 'Add Utility User'} handleClose={closeModal} showFooter={false}>
                        <AddUpdateSolutionProvider providerDetails={selectedUtilityUser} callBack={fetchUtilityUsers} closeModal={closeModal} />
                    </CustomModal>
                ) || ''
            }

            {
                showRoleModal && (
                    <CustomModal isShow={showRoleModal} title={'Update Role'} handleClose={closeRoleModal} showFooter={false}>
                        <UpdateRole providerDetails={selectedUtilityUser} callBack={fetchUtilityUsers} closeModal={closeRoleModal} />
                    </CustomModal>
                ) || ''
            }

            {/* {
                showConfirmModal && (
                    <CustomAlert 
                        isShow={showConfirmModal} 
                        title='Delete Confirmation' 
                        handleClose={closeModal} 
                        content={`Are you sure to delete the Utility user, <strong>${ selectedUtilityUser && selectedUtilityUser?.categoryName || ''}</strong>?`}
                        footerButtonYes={true}
                        footerButtonYesText={'Yes, delete it'}
                        footerButtonYesAction={() => handleProviderDelete()}
                        footerButtonYesClass={'btn btn-sm btn-primary'}
                        footerButtonNo={true}
                        footerButtonNoText={'Cancel'}
                        footerButtonNoAction={() => closeModal()}
                        footerButtonNoClass={'btn btn-sm btn-secondary'}
                    />
                ) || ''
            } */}
        </div>
    )
}

export default UtilityUserList;
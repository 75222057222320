
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux'

import { usePinApi } from '../../../resources/hooks/api/pinApiHook';
import { getSolutionProviderDetails } from '../../../store/solutionProvider/selectors'

interface Props {

}
const UserPin: React.FC<Props> = () => {

    const { list: listPin } = usePinApi();
    const profileDetails = useSelector(getSolutionProviderDetails);
    const [profilePins, setProfilePins] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getPins();
    }, [])

    const getPins = () => {
        const params = {
            roleId: 1,
            UserId: profileDetails.userId
        }
        setIsLoading(true);
        listPin(params, (message: string, resp: any) => {
            setIsLoading(false);
            setProfilePins(resp['data']['objListPin']);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    return (
        <div className="profile_right">
            <div className="d-flex align-items-center justify-content-between">
                <h3>Test Flights</h3>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
                {
                    profilePins.map((pin: any, index: number) => (
                        <div className="pin_list">
                            <div className="row">
                                <div className="col-md-5">
                                    {
                                        pin.pinImage && <img src={pin?.pinImage} /> || <img src="/assets/images/no-image.jpg" />
                                    }                                    
                                </div>
                                <div className="col-md-7">
                                    <div className="problem_statement pb-0">
                                        <h3>{pin?.pinTitle}</h3>
                                        {
                                            pin?.objPinsCategory.map((category: any, index: number) => (
                                                <div key={index}>
                                                    <div className="pin_category">
                                                        <h4>{category?.categoryName}</h4>
                                                        <ul>
                                                            {
                                                                category?.objSubcategories.map((subCategory: any, rowNumber: number) => (
                                                                    <li key={rowNumber}>{subCategory?.subCategoryName}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    {/* <div className="bill-body-text-like-inner-sec p-0 w-50">
                                                        <ul>
                                                            <li>
                                                                <a href="#"><i className="dripicons-thumbs-up"></i> 1.2k</a>
                                                            </li>
                                                            <li className="ml-3">
                                                                <a href="#"><i className="dripicons-thumbs-down"></i> 3.6k</a>
                                                            </li>
                                                            <li className="ml-3">
                                                                <a href="#"><i className="dripicons-message"></i> 3k</a>
                                                            </li>
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    )
}

export default UserPin;
import moment from "moment"


export const getPinStatus = (status: number) => {
  if (status == 0) {
    return 'Pending Review'
  } else if (status == 1) {
    return 'Archive'
  } else if (status == 2) {
    return 'Future review'
  } else if (status == 3) {
    return 'Under Review'
  } else if (status == 4) {
    return 'Active Test Flight'
  } else if (status == 5) {
    return 'Completed'
  } else if (status == 6) {
    return 'Active'
  } else if (status == 7) {
    return 'Verified'
  } else {
    return ''
  }

}   

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2'
import { usePinApi } from '../../../resources/hooks/api/pinApiHook'
import { CustomPagination } from '../../../components/CustomPagination';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';
import { getSortIcon, replaceUrlParams, showDate } from '../../../resources/utils/functions';
import { getPinStatus } from '../../../resources/utils/functionsStatic';
import PinUploadModal from './PinUpload.modal';
import Search from '../../../components/search/Search';
import TitleBar from '../../../components/TitleBar';
import { URLS } from '../../../resources/constants';

interface Props {

}

interface PaginationI {
    page: number,
    total: number,
    per_page: number
}
const PinList: React.FC<Props> = () => {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const [pins, setPins] = useState<any>({
        data: [],
        total: 0
    });
    const [pinUploadModalShow, setPinUploadModalShow] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [selectedPin, setSelectedPin] = useState<any | null>(null);
    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationI>({
        page: 1,
        total: 10,
        per_page: 10
    })

    const { list: pinList, updateApproveStatus, remove: pinRemove } = usePinApi();

    useEffect(() => {
        getPins();
    }, [pagination, search, sort, sortBy])

    const updatePinApproveStatus = (pinId: number, approveStatus: number) => {
        const params = {
            roleId: 1,
            pinId: pinId,
            isApproved: approveStatus
        }
        console.log('params: ', params)
        setLoader(true);
        updateApproveStatus(params, (message: string, resp: any) => {
            setLoader(false);
            toast.success(message)
            getPins();
        }, (message: string, resp: any) => {
            setLoader(false);
            toast.error(message)
        })
    }

    const getPins = (filter?: any) => {
        const params = {
            RoleId: 1,
            PageNumber: pagination.page,
            PageSize: pagination.total,
            SearchText: search,
            SortOrder: sort,
            SortColumn: sortBy
        }
        showLoader();
        pinList(params, (message: string, resp: any) => {
            hideLoader();
            setPins({
                data: resp['data']['objListPin'],
                total: resp['totalRecords']
            })
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handleDeletePin = (pin: any) => {
        const params = {
            pinsId: pin['pinId']
        }
        showLoader();
        pinRemove(params, (message: string, resp: any) => {
            hideLoader();
            closeDelelePin();
            toast.success(message)
            getPins();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const deletePin = (pin: any) => {
        // setSelectedPin(pin);
        // setShowConfirmModal(true);
        Swal.fire({
            title: `Are you sure to delete the test flight, ${pin['pinTitle']}?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeletePin(pin)
            }
        })
    }

    const closeDelelePin = () => {
        setSelectedPin(null);
        setShowConfirmModal(false);
    }

    const handlePageChange = (pageNumber: number) => {
        setPagination({
            ...pagination,
            page: pageNumber
        })
    }

    const pinUploadModalClose = () => {
        setPinUploadModalShow(false);
    }

    const sortDataHandler = (sortByField: string) => {
        if (sortBy !== sortByField) {
            setSort('asc')
            setSortBy(sortByField)
        } else {
            setSort((sort == '') ? 'asc' : ((sort == 'asc') ? 'desc' : ''))
        }
    }

    const sortIconHandler = (sortByField: string) => {
        return getSortIcon(sort, sortBy, sortByField)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <TitleBar title={'Test Flight List'} breadcrumbs={{
                    'Test Flight': '#',
                }} />


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className='text-right mb-3 d-flex justify-content-between'>

                                    <Search setSearch={(str: string) => {
                                        setSearch(str)
                                        setPins({ data: [], total: 0 })
                                        setPagination({ ...pagination, page: 1 })
                                    }} />


                                    <div className='d-flex gap-3 align-items-center'>
                                        <button className='btn btn-success mx-3' onClick={() => setPinUploadModalShow(true)}>
                                            <i className="dripicons-cloud-upload"></i>  Bulk Test Flight Upload
                                        </button>

                                        <Link to={URLS.PINS.ADD} className='btn btn11 btn-primary'>Add Test Flight</Link>
                                    </div>
                                </div>
                                <div className="table-responsive custom-table">
                                    <table className="table table-striped table-bordered dt-responsive nowrap no-footer dtr-inline" style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className='cursor-pointer' onClick={() => sortDataHandler('pintitle')}>
                                                        Test Flight Title
                                                        {sortIconHandler('pintitle')}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='cursor-pointer' onClick={() => sortDataHandler('owner')}>
                                                        Owner
                                                        {sortIconHandler('owner')}
                                                    </span>
                                                </th>
                                                <th>
                                                    <span className='cursor-pointer' onClick={() => sortDataHandler('category')}>
                                                        Category
                                                        {sortIconHandler('category')}
                                                    </span>
                                                </th>

                                                <th>
                                                    <span className='cursor-pointer' onClick={() => sortDataHandler('date')}>
                                                        Date
                                                        {sortIconHandler('date')}
                                                    </span>
                                                </th>

                                                <th>
                                                    <span className='cursor-pointer' onClick={() => sortDataHandler('status')}>
                                                        Status
                                                        {sortIconHandler('status')}
                                                    </span>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pins.data.map((pin: any, index: number) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <NavLink to="javascript:void(0)" className="view" onClick={() => navigate(`/pins/view/${pin['pinId']}`)}>
                                                                {pin['pinTitle']}
                                                            </NavLink>
                                                        </td>
                                                        <td>{pin['firstName'] + ' ' + pin['lastName']}</td>
                                                        <td>{
                                                            pin['objPinsCategory'].map((cat: any) => cat['categoryName'])
                                                                .join(', ')
                                                        }</td>
                                                        <td>{showDate(pin['createdDate'])}</td>
                                                        <td>
                                                            <span className={`badge badge-${(pin['isApproved'] == 1) ? 'danger' : ((pin['isApproved'] == 6) ? 'success' : 'primary')}`}>
                                                                {
                                                                    (pin['isApproved'] == 7) && <i className="dripicons-checkmark"></i>
                                                                }
                                                                &nbsp;
                                                                {getPinStatus(pin['isApproved'])}
                                                            </span>
                                                        </td>
                                                        {/* <td> { getPinStatus(pin['isApproved'])  +pin['isPublished'] ? 'Published' : 'Not Published' }</td> */}
                                                        <td>
                                                            <div className="d-flex gap-10 action-btns">
                                                                <NavLink to="javascript:void(0)" className="view" onClick={() => navigate(replaceUrlParams(URLS.PINS.DETAILS, { id: pin['pinId'] }))}>
                                                                    <i className="dripicons-preview"></i>
                                                                </NavLink>
                                                                <NavLink to="javascript:void(0)" className="edit" onClick={() => navigate(replaceUrlParams(URLS.PINS.EDIT, { id: pin['pinId'] }))}>
                                                                    <i className="dripicons-pencil"></i>
                                                                </NavLink>
                                                                <NavLink to="javascript:void(0)" className="delete" onClick={() => deletePin(pin)}>
                                                                    <i className="dripicons-trash"></i>
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                        <div className="d-flex gap-10 action-btns">
                                                            {
                                                                pin['isPublished'] && (
                                                                    <NavLink to="javascript:void(0)" className="delete" onClick={() => updatePinApproveStatus(pin['pinId'], 0)}>
                                                                        <i className="dripicons-cross"></i>
                                                                    </NavLink>
                                                                ) || (
                                                                    <NavLink to="javascript:void(0)" className="edit" onClick={() => updatePinApproveStatus(pin['pinId'], 1)}>
                                                                        <i className="dripicons-checkmark"></i>
                                                                    </NavLink>
                                                                )
                                                            }
                                                        </div>
                                                    </td> */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>

                                    {/* {'pins.total=' + pins.total}
                                    {'pagination.total=' + pagination.total}
                                    {'pagination.page=' + pagination.page} */}
                                    {
                                        pins.total > 1 && <CustomPagination
                                            className='float-right'
                                            totalItems={pins.total}
                                            itemsPerPage={pagination.total}
                                            currentPage={pagination.page}
                                            onPageChange={handlePageChange}
                                        />
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {
                    pinUploadModalShow &&
                    <PinUploadModal
                        show={pinUploadModalShow}
                        callbackFuncs={getPins}
                        close={pinUploadModalClose}
                    />
                }

            </div>

            {/* {
                showConfirmModal && (
                    <CustomAlert 
                        isShow={showConfirmModal} 
                        title='Delete Confirmation' 
                        handleClose={closeDelelePin} 
                        content={`Are you sure to delete the test flight, <strong>${ selectedPin && selectedPin.pinTitle || ''}</strong>?`}
                        footerButtonYes={true}
                        footerButtonYesText={'Yes, delete'}
                        footerButtonYesAction={() => handleDeletePin()}
                        footerButtonYesClass={'btn btn-sm btn-primary'}
                        footerButtonNo={true}
                        footerButtonNoText={'Cancel'}
                        footerButtonNoAction={() => closeDelelePin()}
                        footerButtonNoClass={'btn btn-sm btn-secondary'}
                    />
                ) || ''
            } */}
        </div>
    )
}

export default PinList;

import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

// import {URLS} from '../resources/constants'
import Login from '../scenes/public/login'
import ForgotPassword from '../scenes/public/forgot-password'
import ResetPassword from '../scenes/public/reset-password'
import Dashboard from '../scenes/privte/Dashboard'
import SolutionProviderList from '../scenes/privte/SolutionProvider/List'
import SolutionProviderDetails from '../scenes/privte/SolutionProvider/Details'
import UtilityUserDetails from '../scenes/privte/UtilityUser/Details'
import UtilityUserList from '../scenes/privte/UtilityUser/List'
import PinList from '../scenes/privte/Pin/List'
import PinAdd from '../scenes/privte/Pin/Add'
import PinEdit from '../scenes/privte/Pin/Edit'
import CategoryList from '../scenes/privte/Category/List'
import { ProtectedRoute } from './ProtectedRoute'
import { ProtectedLayout } from '../scenes/layout/ProtectedLayout'
import { NonProtectedLayout } from '../scenes/layout/NonProtectedLayout'
import NotFoundPage from '../scenes/public/NotFoundPage'
import PinDetails from '../scenes/privte/Pin/Details'
import AdminProfile from '../scenes/privte/profile/AdminProfile'
// import ScrollToTop from '../ScrollToTop'
// import Header from '../scenes/Header'

const Routes = createBrowserRouter([
    {
        path: "",
        element: <NonProtectedLayout />,
        children: [{
            path: "/",
            element: <Login />
        }, {
            path: "login",
            element: <Login />
        }, {
            path: "forgot-password",
            element: <ForgotPassword />
        }, {
            path: "reset-password",
            element: <ResetPassword />
        }]
    },
    {
        path: "",
        element: <ProtectedLayout />,
        children: [{
            path: "profile",
            element: <AdminProfile />
        }, {
            path: "dashboard",
            element: <Dashboard />
        }, {
            path: "solution-provider",
            element: <SolutionProviderList />
        },
        {
            path: "solution-provider/:id",
            element: <SolutionProviderDetails />
        },
        {
            path: "utility-user",
            children: [{
                path: '',
                element: <UtilityUserList />
            }, {
                path: ':id',
                element: <UtilityUserDetails />
            }]
        },
        {
            path: "pins",
            children: [{
                path: '',
                element: <PinList />,
            }, {
                path: 'add',
                element: <PinAdd />,
            }, {
                path: 'edit/:id',
                element: <PinEdit />,
            }, {
                path: 'view/:id',
                element: <PinDetails />,
            }
            ]
        }, {
            path: "categories",
            element: <CategoryList />
        }]
    }, {
        path: '*',
        element: <NotFoundPage />
    }
], {
    basename: process.env.PUBLIC_URL
});

export default Routes;

import { all, takeLatest } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../resources/constants";
import * as UserSaga from './user'
import * as PinSaga from './pin'
import * as SolutionProvider from './solutionProvider'
import * as Category from './category'
import * as SettingsSaga from './settings'
import * as TableDataSaga from './tableData'

export default function* rootSaga() {
    yield all([
        takeLatest(SAGA_ACTIONS.USER.LOGIN, UserSaga.login),
        takeLatest(SAGA_ACTIONS.USER.FORGET_PASSWORD, UserSaga.forgetPassword),
        takeLatest(SAGA_ACTIONS.USER.FORGET_PASSWORD_RESET, UserSaga.forgetPasswordReset),
        takeLatest(SAGA_ACTIONS.USER.FETCH_USER_BY_EMAIL, UserSaga.fetchUserByEmail),
        takeLatest(SAGA_ACTIONS.USER.RESEND_EMAIL_VERIFICATION, UserSaga.sendEmailVerification),

        takeLatest(SAGA_ACTIONS.PIN.ADD, PinSaga.add),
        takeLatest(SAGA_ACTIONS.PIN.EDIT, PinSaga.edit),
        takeLatest(SAGA_ACTIONS.PIN.FETCH_SINGLE, PinSaga.fetchSingle),
        takeLatest(SAGA_ACTIONS.PIN.LIST, PinSaga.list),
        takeLatest(SAGA_ACTIONS.PIN.UPDATE_APPROVED_STATUS, PinSaga.updateApproveStatus),
        takeLatest(SAGA_ACTIONS.PIN.DELETE, PinSaga.remove),
        takeLatest(SAGA_ACTIONS.PIN.COMMENTS, PinSaga.comments),
        takeLatest(SAGA_ACTIONS.PIN.DELETE_COMMENT, PinSaga.deleteComment), 
        takeLatest(SAGA_ACTIONS.PIN.PIN_REVIEW, PinSaga.pinReview), 
        takeLatest(SAGA_ACTIONS.PIN.GET_PIN_REVIEW, PinSaga.getPinReview), 
        takeLatest(SAGA_ACTIONS.PIN.PIN_BULK_UPLOAD, PinSaga.bulkFileUpload), 

        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.ADD, SolutionProvider.add),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.ADD_PROFILE, SolutionProvider.addProfile),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.EDIT, SolutionProvider.edit),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.APPROVAL, SolutionProvider.approval),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.DELETE, SolutionProvider.remove),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.LIST, SolutionProvider.list),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE, SolutionProvider.fetchProfile),
        takeLatest(SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE_CATEGORY_MAP, SolutionProvider.fetchCategoryMap),

        takeLatest(SAGA_ACTIONS.CATEGORY.LIST, Category.list),
        takeLatest(SAGA_ACTIONS.CATEGORY.ADD, Category.addCat),
        takeLatest(SAGA_ACTIONS.CATEGORY.UPDATE, Category.updateCat),
        takeLatest(SAGA_ACTIONS.CATEGORY.DELETE, Category.deleteCat),
        takeLatest(SAGA_ACTIONS.SUB_CATEGORY.ADD, Category.addSubCat),
        takeLatest(SAGA_ACTIONS.SUB_CATEGORY.UPDATE, Category.updateSubCat),
        takeLatest(SAGA_ACTIONS.SUB_CATEGORY.DELETE, Category.deleteSubCat),

        takeLatest(SAGA_ACTIONS.SETTINGS, SettingsSaga.fetchSettings),

        takeLatest(SAGA_ACTIONS.TABLE_DATA, TableDataSaga.fetchTableData),
        
    ])
}
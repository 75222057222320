import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2'

import { getUser, getAuthenticated } from '../../../store/user/selectors'
import { useCategoryApi } from '../../../resources/hooks/api/categoryApiHook'
import { NavLink } from 'react-router-dom';
import DataTable from '../../../components/DataTable/DataTable';
import CustomModal from '../../../components/CustomModal';
import CustomAlert from '../../../components/CustomAlert';
import AddUpdateCategory from './AddUpdate';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';
import TitleBar from '../../../components/TitleBar';
// import { CustomPagination } from '../../../resources/utils/CustomPagination';

interface Props {

}

interface PaginationI {
    page: number,
    total: number,
    per_page: number
}
const CategoryList: React.FC<Props> = () => {

    const { showLoader, hideLoader } = useAppLoader();
    const [categories, setCategories] = useState<any>({
        data: [],
        total: 0
    });
    const [loader, setLoader] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationI>({
        page: 1,
        total: 10,
        per_page: 10
    })
    const [selectedParentCategory, setSelectedParentCategory] = useState<any | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const { list: categoryList, deleteCat, deleteSubCat } = useCategoryApi();

    useEffect(() => {
        getCategories();
    }, [])

    const closeModal = () => {
        setShowModal(false);
        setShowConfirmModal(false);
        setSelectedCategory(null)
        setSelectedParentCategory(null)
    }

    const getCategories = (filter?: any) => {
        const params = {
            RoleId: 1,
            // PageNumber: pagination.page,
            // PageSize: pagination.total
        }
        showLoader();
        categoryList(params, (message: string, resp: any) => {
            hideLoader();
            // toast.success(message)
            setCategories({
                data: resp['data'],
                total: resp['totalRecords']
            })
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const addCategory = (parentCategory: any | null) => {
        setSelectedCategory(null);
        setSelectedParentCategory(parentCategory)
        setShowModal(true);
    }

    const editCategory = (category: any, parentCategory: any | null) => {
        setSelectedCategory(category);
        setSelectedParentCategory(parentCategory)
        setShowModal(true);
    }

    const handleDeleteCategory = (category: any, parentCategory: any | null) => {
        if (parentCategory) {
            const params = {
                RoleId: 1,
                subCategoryId: category['subCategoryId']
            }

            showLoader();
            deleteSubCat(params, (message: string, resp: any) => {
                hideLoader();
                getCategories();
                closeModal();
                toast.success(message)
            }, (message: string, resp: any) => {
                hideLoader();
                toast.error(message)
            })
        } else {
            const params = {
                RoleId: 1,
                categoryId: category['categoryId']
            }
            showLoader();
            deleteCat(params, (message: string, resp: any) => {
                hideLoader();
                getCategories();
                closeModal();
                toast.success(message)
            }, (message: string, resp: any) => {
                hideLoader();
                toast.error(message);
            })
        }
    }

    const deleleCategory = (category: any, parentCategory: any | null) => {
        // setSelectedCategory(category);
        // setSelectedParentCategory(parentCategory)
        // setShowConfirmModal(true);
        Swal.fire({
            title: `Are you sure to delete the ${parentCategory && 'sub category' || 'category'}, ${parentCategory && category?.subCategoryName || category?.categoryName}?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteCategory(category, parentCategory)
            }
        })
    }

    const handlePageChange = () => {

    }

    return (
        <div className="page-content">
            <div className="container-fluid"> 

                <TitleBar title={'Category List'} breadcrumbs={{
                    'Category': '#',
                }} />


                <div className="card">
                    <div className="card-body">

                        <div className="accordion category_subaccordian" id="accordionExample">
                            <div className='row col-12'>
                                <a href="javascript:void(0)" onClick={() => addCategory(null)} className="add_category_btn ml-auto">Add Category</a>

                            </div>
                            <div className="category_title" >
                                <div className="row align-items-center">
                                    <div className="col-9 acordian_thead">Category Name</div>
                                    <div className="col-3 acordian_thead d-flex align-items-center">
                                        Action
                                    </div>
                                </div>
                            </div>

                            {
                                categories['data'].map((category: any, index: number) => (
                                    <div className="" key={index}>
                                        <div className="card-header" id={"heading" + index}>
                                            <h2 className="mb-0">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <button className="btn btn-link btn-block text-left" type="button"
                                                            data-toggle="collapse" data-target={"#collapse" + index}
                                                            aria-expanded="true" aria-controls={"collapse" + index}>
                                                            {category['categoryName']}
                                                        </button>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="d-flex gap-10 action-btns">
                                                            <a href="javascript:void(0)" className="edit" onClick={() => editCategory(category, null)}>
                                                                <i className="dripicons-pencil"></i>
                                                            </a>
                                                            <a href="javascript:void(0)" className="delete" onClick={() => deleleCategory(category, null)}>
                                                                <i className="dripicons-trash"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h2>
                                        </div>

                                        <div id={"collapse" + index} className="collapse" aria-labelledby={"heading" + index} data-parent="#accordionExample">
                                            <div className="card-body">
                                                <div className="table-responsive custom-table">
                                                    <table id=""
                                                        className="table table-striped table-bordered dt-responsive nowrap dataTable no-footer dtr-inline"
                                                        style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Sub Category Name</th>
                                                                <th className="d-flex align-items-center">Action
                                                                    <a href="javascript:void(0)" className="add_category_btn ml-auto" onClick={() => addCategory(category)}>Add Sub Category</a>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                category['subCategories'].map((subCat: any, rowNum: number) => (
                                                                    <tr key={rowNum}>
                                                                        <td>{subCat && subCat.subCategoryName || ''}</td>
                                                                        <td>
                                                                            <div className="d-flex gap-10 action-btns">
                                                                                <a href="javascript:void(0)" className="edit" onClick={() => editCategory(subCat, category)}>
                                                                                    <i className="dripicons-pencil"></i>
                                                                                </a>
                                                                                <a href="javascript:void(0)" className="delete" onClick={() => deleleCategory(subCat, category)}>
                                                                                    <i className="dripicons-trash"></i>
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                showModal && (
                    <CustomModal isShow={showModal} title={selectedCategory ? (selectedParentCategory ? 'Update Category' : 'Update Category') : selectedParentCategory ? 'Add Sub Category' : 'Add Category'} handleClose={closeModal} showFooter={false}>
                        <AddUpdateCategory category={selectedCategory} callBack={getCategories} closeModal={closeModal} parentCategory={selectedParentCategory} />
                    </CustomModal>
                ) || ''
            }

            {/* {
                showConfirmModal && (
                    <CustomAlert 
                        isShow={showConfirmModal} 
                        title='Delete Confirmation' 
                        handleClose={closeModal} 
                        content={`Are you sure to delete the ${ selectedCategory && selectedCategory['categoryName'] && 'category' || 'sub category'}, <strong>${ selectedCategory && selectedCategory?.categoryName || selectedCategory?.subCategoryName || ''}</strong>?`}
                        footerButtonYes={true}
                        footerButtonYesText={'Yes, delete it'}
                        footerButtonYesAction={() => handleDeleteCategory()}
                        footerButtonYesClass={'btn btn-sm btn-primary'}
                        footerButtonNo={true}
                        footerButtonNoText={'Cancel'}
                        footerButtonNoAction={() => closeModal()}
                        footerButtonNoClass={'btn btn-sm btn-secondary'}
                    />
                ) || ''
            } */}

        </div>
    )
}

export default CategoryList;
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import { usePinApi } from '../../../resources/hooks/api/pinApiHook'
import { dateAgo } from '../../../resources/utils/functions';
import CustomAlert from '../../../components/CustomAlert';
import DecisionMakingModal from './DecisionMaking.modal';
import ReviewListModal from './ReviewList.modal';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';
import parse from 'html-react-parser'
import { getPinStatus } from '../../../resources/utils/functionsStatic';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/user/selectors';
import TitleBar from '../../../components/TitleBar';
import { URLS } from '../../../resources/constants';

interface Props {

}

const PinDetails: React.FC<Props> = () => {
    const { id: pinId } = useParams();

    const { showLoader, hideLoader } = useAppLoader();
    const { fetchSingle: fetchSinglePin, comments: comments, deleteComment: deleteComment, pinReview: pinReview } = usePinApi();
    const [pinDetails, setPinDetails] = useState<any | null>(null);
    const [pinComments, setPinComments] = useState<any | null>([]);
    const [deleteCommentModal, setDeleteCommentModal] = useState<boolean>(false);
    const [deleteCommentId, setDeleteCommentId] = useState<any>(null);
    const [reviewListModalShow, setReviewListModalShow] = useState<boolean>(false);
    const [decisionMakingModalShow, setDecisionMakingModalShow] = useState<boolean>(false);
    const [decisionStatus, setDecisionStatus] = useState<boolean>(false);

    const profileDetails = useSelector(getUser);


    useEffect(() => {
        handleFetchPin();
        getPinComments();
    }, [pinId])


    const handleFetchPin = () => {
        const params = {
            pinId: pinId
        }
        // showLoader();
        fetchSinglePin(params, (message: string, resp: any) => {
            if (resp['data'] && resp['data']['objListPin'] && resp['data']['objListPin'].length) {
                setPinDetails(resp['data']['objListPin'][0]);
            }
            // hideLoader()
        }, (message: string, resp: any) => {
            hideLoader()
            toast.error(message)
        })
    }

    const getPinComments = () => {
        const params = { PinsId: pinId }
        comments(params, (message: string, resp: any) => {
            if (resp['data'] && resp['data']['objListPin'] && resp['data']['objListPin'].length) {
                setPinComments(resp['data']['objListPin'][0]['objPinComments']);
            }
        }, (message: string, resp: any) => {
            toast.error(message)
        })
    }

    const deleteCommentHandler = (parentCommentId: any) => {
        setDeleteCommentModal(true);
        setDeleteCommentId(parentCommentId);
    }

    const closeDeleteComment = () => {
        setDeleteCommentModal(false);
    }

    const handleDeleteComment = () => {
        const params = {
            pinCommentsId: deleteCommentId
        }
        deleteComment(params, (message: string, resp: any) => {
            closeDeleteComment();
            toast.success(message)
            getPinComments();
        }, (message: string, resp: any) => {
            toast.error(message)
        })
    }

    const decisionMakingModalHandler = (statusCode: any) => {
        if (statusCode == 7) {
            let params: any = {
                "pinsId": pinDetails?.pinsId,
                "reviewStatus": 7,
                "comment": 'Verified from admin panel.',
                "reviewedBy": profileDetails?.userId,
            }

            pinReview(params, (message: string, resp: any) => {
                toast.success(message)
                handleFetchPin();
            }, (message: string, resp: any) => {
                toast.error(message)
            })
        } else {
            setDecisionMakingModalShow(true);
            setDecisionStatus(statusCode);
        }
    }

    const decisionMakingModalClose = () => {
        setDecisionMakingModalShow(false);
    }
    const decisionMakingCallBack = () => {
        setDecisionMakingModalShow(false);
        handleFetchPin();
    }

    const reviewListModalClose = () => {
        setReviewListModalShow(false);
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <TitleBar title={'Test Flight Details'} breadcrumbs={{
                    'Test Flight': URLS.PINS.LIST,
                    'Details': '#',
                }} />

                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <div className="pin_details">

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card">
                                            <img className="pin_image" src={pinDetails?.pinImage ? pinDetails?.pinImage : 'https://ik.imagekit.io/innovationforce/NoImage/no-pin-image.jpg?tr=w-800'} alt="" />
                                            <div className="card-body">
                                                <h5 className="card-title text-truncate whitespace_normal">{pinDetails?.pinTitle}</h5>
                                            </div>
                                        </div>
                                        <div className="card bill-body-text-like-inner-sec">
                                            <ul>
                                                <li>
                                                    <a href="#"><i className="dripicons-thumbs-up"></i> {pinDetails?.totalLikes}</a>
                                                </li>
                                                {/* <li>
                                                    <a href="#"><i className="dripicons-thumbs-down"></i> 480</a>
                                                </li> */}
                                                <li>
                                                    <a href="#"><i className="dripicons-preview"></i> {pinDetails?.totalViews}</a>
                                                </li>
                                                <li>
                                                    <a href="#"><i className="dripicons-forward"></i> {pinDetails?.totalShares}</a>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li> Current Status: &nbsp;&nbsp;
                                                    <span className={`badge badge-${(pinDetails?.isApproved == 1) ? 'danger' : ((pinDetails?.isApproved == 6) ? 'success' : 'primary')}`}>
                                                        {
                                                            (pinDetails?.isApproved == 7) && <><i className="dripicons-checkmark"></i>&nbsp; </>
                                                        }

                                                        {getPinStatus(pinDetails?.isApproved)}
                                                    </span>
                                                </li>
                                            </ul>

                                            {
                                                (pinDetails?.isApproved == 0) &&
                                                <ul>
                                                    <li>
                                                        <button className='btn btn-success' onClick={() => decisionMakingModalHandler(6)}>
                                                            <i className="dripicons-checkmark"></i>  Active
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className='btn btn-danger' onClick={() => decisionMakingModalHandler(1)}>
                                                            <i className="dripicons-return"></i>  Archive
                                                        </button>
                                                    </li>
                                                </ul>
                                            }

                                            <ul>
                                                {
                                                    (pinDetails?.isApproved == 1) &&
                                                    <li> Change Status:  &nbsp;&nbsp;
                                                        <button className='btn btn-success' onClick={() => decisionMakingModalHandler(6)}>
                                                            <i className="dripicons-checkmark"></i>  Active
                                                        </button>
                                                    </li>
                                                }
                                                {
                                                    (pinDetails?.isApproved == 6 || pinDetails?.isApproved == 7) &&
                                                    <li> Change Status:  &nbsp;&nbsp;
                                                        <button className='btn btn-danger' onClick={() => decisionMakingModalHandler(1)}>
                                                            <i className="dripicons-return"></i>  Archive
                                                        </button>&nbsp;&nbsp;

                                                        {
                                                            (pinDetails?.isApproved == 6) &&
                                                            <button className='btn btn-primary' onClick={() => decisionMakingModalHandler(7)}>
                                                                <i className="dripicons-checkmark"></i>  Verify
                                                            </button>

                                                        }
                                                    </li>
                                                }
                                            </ul>
                                            {
                                                (pinDetails?.isApproved == 1 || pinDetails?.isApproved == 6 || pinDetails?.isApproved == 7) &&
                                                <ul>
                                                    <li>
                                                        <button className='btn btn-success' onClick={() => setReviewListModalShow(true)}>
                                                            <i className="dripicons-preview"></i>  Check Review History
                                                        </button>
                                                    </li>
                                                </ul>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card p-3">
                                            <div className="problem_statement">
                                                <h3>Current State</h3>
                                                <p>{pinDetails?.currentState}</p>
                                            </div>
                                            <div className="problem_statement">
                                                <h3>Problem Statement </h3>
                                                <p>{pinDetails?.problemStatement ? pinDetails?.problemStatement : '--'}</p>
                                            </div>
                                            <div className="problem_statement">
                                                <h3>Desired Outcome </h3>
                                                <p>{pinDetails?.desiredOutcome ? pinDetails?.desiredOutcome : '--'}</p>
                                            </div>
                                            <div className="problem_statement">
                                                <h3>Solutions </h3>
                                                <p>{pinDetails?.solutions ? parse(pinDetails?.solutions) : '--'}</p>
                                            </div>


                                            <div className="problem_statement">
                                                <h3>Test Flight Tags</h3>
                                            </div>
                                            {
                                                pinDetails?.objPinsCategory && pinDetails?.objPinsCategory.length > 0 &&
                                                pinDetails?.objPinsCategory.map((catRow: any) =>

                                                    <div key={Math.random()}>
                                                        {
                                                            (catRow?.objSubcategories && catRow?.objSubcategories.length > 0) &&
                                                            <div className="problem_statement" key={Math.random()}>
                                                                <b>  {catRow?.categoryName}</b>
                                                                {
                                                                    catRow?.objSubcategories.map((catSubRow: any, index: any) =>
                                                                        <p key={Math.random()}>{index + 1} . &nbsp; {catSubRow?.subCategoryName}</p>
                                                                    )
                                                                }
                                                            </div>
                                                        }



                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="card comment_post p-3 mt-3">
                                    <div className="text-dark">
                                        <div className="app">
                                            <h4 className="mb-2">Comments <span>({pinComments.length})</span></h4>

                                            <div className="">
                                                {
                                                    pinComments && pinComments.length > 0 &&
                                                    pinComments.map((item: any) =>
                                                        <div className="py-3">
                                                            <div className="d-flex comment">
                                                                <img className="rounded-circle comment-img" src={item?.parentProfilePic} />
                                                                <div className="flex-grow-1 ms-3">
                                                                    <div className="mb-1">
                                                                        <a href="#" className="fw-bold link-dark me-1">{item?.parentFirstName} {item?.parentLastName}</a>  &nbsp;&nbsp;
                                                                        <span className="text-muted text-nowrap">{dateAgo(item?.parentCommentDate)}</span>
                                                                    </div>
                                                                    <div className="mb-2">{item?.parentComments}</div>
                                                                    <div className="hstack align-items-center mb-2">
                                                                        <a className="link-danger small" href="#" onClick={() => deleteCommentHandler(item?.parentCommentId)}>DELETE</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    pinComments && pinComments.length == 0 &&
                                                    (
                                                        <div className="py-3">
                                                            <div className="d-flex comment">
                                                                <div className="flex-grow-1 ms-3">
                                                                    <div className="mb-2">No comments found!!!</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {
                    deleteCommentModal && (
                        <CustomAlert
                            isShow={deleteCommentModal}
                            title='Delete Confirmation'
                            handleClose={closeDeleteComment}
                            content={`Are you sure to delete this comment?`}
                            footerButtonYes={true}
                            footerButtonYesText={'Yes, delete'}
                            footerButtonYesAction={() => handleDeleteComment()}
                            footerButtonYesClass={'btn btn-sm btn-primary'}
                            footerButtonNo={true}
                            footerButtonNoText={'Cancel'}
                            footerButtonNoAction={() => closeDeleteComment()}
                            footerButtonNoClass={'btn btn-sm btn-secondary'}
                        />
                    )
                }
                {
                    decisionMakingModalShow &&
                    <DecisionMakingModal
                        show={decisionMakingModalShow}
                        pinDetails={pinDetails}
                        decisionStatus={decisionStatus}
                        close={decisionMakingModalClose}
                        callbackFuncs={decisionMakingCallBack}
                    />
                }

                {
                    reviewListModalShow &&
                    <ReviewListModal
                        show={reviewListModalShow}
                        pinDetails={pinDetails}
                        close={reviewListModalClose}
                    />
                }
            </div>
        </div>
    )
}

export default PinDetails;
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook'
import { useUserApi } from '../../../resources/hooks/api/userApiHook'
import { CustomPagination } from '../../../components/CustomPagination';
import CustomModal from '../../../components/CustomModal';
import AddUpdateSolutionProvider from './AddUpdate';
import { USER_ROLE } from '../../../resources/constants';
import { getUser } from '../../../store/user/selectors';
import TitleBar from '../../../components/TitleBar';
import Search from '../../../components/search/Search';
import { getSortIcon } from '../../../resources/utils/functions';
import { LuUserCog } from 'react-icons/lu';
import UpdateRole from '../roleUpdate';

interface Props {

}

interface PaginationI {
    page: number,
    total: number
}
const SolutionProviderList: React.FC<Props> = () => {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [selectedProvider, setSelectedProvider] = useState<any | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    const loggedUser = useSelector(getUser);
    console.log('====loggedUser', loggedUser)
    const [providers, setProviders] = useState<any>({
        data: [],
        total: 0
    });
    const [pagination, setPagination] = useState<PaginationI>({
        page: 1,
        total: 10
    })
    const [search, setSearch] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('');

    const { list: providerListCall, remove: removeProvider, userApproval, resendEmailVerification } = useUserApi();

    useEffect(() => {
        fetchProviders();
    }, [pagination, search, sort, sortBy])

    const fetchProviders = () => {
        const params = {
            UserType: USER_ROLE.SOLUTION_PROVIDER,
            PageNumber: pagination.page,
            PageSize: pagination.total,
            SearchText: search,
            SortOrder: sort,
            SortColumn: sortBy
        }
        showLoader();
        providerListCall(params, (message: string, resp: any) => {
            hideLoader();
            setProviders({
                data: resp['data']['objListUser'],
                total: resp['data']['totalRecords']
            })
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handleProviderDelete = (provider: any) => {
        const params = {
            userId: provider['userId']
        }
        showLoader();
        removeProvider(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            fetchProviders();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handleProviderApproval = (provider: any, makeApproved: boolean) => {
        const params = {
            userId: provider['userId'],
            isApproved: makeApproved
        }
        showLoader();
        userApproval(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            fetchProviders();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }

    const handlePageChange = (currentPage: number) => {
        setPagination({
            ...pagination,
            page: currentPage
        })
    }

    const addProvider = () => {
        setSelectedProvider(null)
        setShowModal(true);
    }

    const editProvider = (provider: any) => {
        setSelectedProvider(provider)
        setShowModal(true);
    }
    const editRoleProvider = (provider: any) => {
        setSelectedProvider(provider)
        setShowRoleModal(true);
    }

    const deleteProvider = (provider: any) => {
        // setSelectedProvider(provider);
        // setShowConfirmModal(true);
        Swal.fire({
            title: `Are you sure to delete the user, ${provider['firstName']} ${provider['lastName']}?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, delete it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleProviderDelete(provider)
            }
        })
    }


    const handleResendEmailVerification = (provider: any) => {
        const params = {
            userID: provider['userId'],
            sentBy: loggedUser.userId,
            emailVerificationBaseURL: process.env.REACT_APP_FRONTEND_BASE_URL + '/email-verification',
        }

        //   "userID": 0,
        //   "sentBy": 0,
        //   "emailVerificationBaseURL": "string"
        showLoader();
        resendEmailVerification(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })
    }
    const resendEmailVerificationFn = (provider: any) => {
        Swal.fire({
            title: `Are you sure you want to resend email verification mail?`,
            showDenyButton: true,
            confirmButtonText: 'Yes, send it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleResendEmailVerification(provider)
            }
        })
    }

    const approvalProvider = (provider: any, makeApproved: any) => {
        Swal.fire({
            title: makeApproved == 1 ? `Do you want to approve ${provider['firstName']} ${provider['lastName']}?` : `Do you want to reject ${provider['firstName']} ${provider['lastName']}?`,
            showDenyButton: true,
            confirmButtonText: makeApproved == 1 ? 'Yes, approve it' : 'Yes, reject it',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                handleProviderApproval(provider, makeApproved)
            }
        })
    }

    const closeModal = () => {
        setShowModal(false);
        setShowConfirmModal(false);
        setSelectedProvider(null)
    }
    const closeRoleModal = () => {
        setShowRoleModal(false);
        setShowConfirmModal(false);
        setSelectedProvider(null)
    }

    const sortDataHandler = (sortByField: string) => {
        if (sortBy !== sortByField) {
            setSort('asc')
            setSortBy(sortByField)
        } else {
            setSort((sort == '') ? 'asc' : ((sort == 'asc') ? 'desc' : ''))
        }
    }

    const sortIconHandler = (sortByField: string) => {
        return getSortIcon(sort, sortBy, sortByField)
    }
    return (
        <div>
            <div className="page-content">
                <div className="container-fluid">
                    <TitleBar title={'Solution Provider'} breadcrumbs={{
                        'Solution Provider': '#',
                    }} />

                    <div className="row">
                        <div className="col-12">
                            <div className="card min-height-280">
                                <div className="card-body">
                                    <div className='text-right mb-3  d-flex justify-content-between'>

                                        <Search setSearch={(str: string) => {
                                            setSearch(str)
                                            setProviders({ data: [], total: 0 })
                                            setPagination({ ...pagination, page: 1 })
                                        }} />
                                        <button type='button' className='btn btn11 btn-primary' onClick={() => addProvider()}>Add Solution Provider</button>
                                    </div>
                                    <div className="table-responsive custom-table">
                                        <table className="table table-striped table-bordered dt-responsive nowrap no-footer dtr-inline" style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('name')}>
                                                            Full Name
                                                            {sortIconHandler('name')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('email')}>
                                                            Email
                                                            {sortIconHandler('email')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('jobtitle')}>
                                                            Job Title
                                                            {sortIconHandler('jobtitle')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('company')}>
                                                            Company
                                                            {sortIconHandler('company')}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        {/* <span className='cursor-pointer' onClick={() => sortDataHandler('workFor')}> */}
                                                        Work For
                                                        {/* {sortIconHandler('workFor')} */}
                                                        {/* </span> */}
                                                    </th>
                                                    <th>
                                                        <span className='cursor-pointer' onClick={() => sortDataHandler('status')}>
                                                            Status
                                                            {sortIconHandler('status')}
                                                        </span>
                                                    </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    providers.data.map((provider: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Link to={`/solution-provider/${provider['userProfileId']}`}>
                                                                    {provider['firstName'] + ' ' + provider['lastName']}
                                                                </Link>
                                                            </td>
                                                            <td>{provider['email']}</td>
                                                            <td>{provider['jobTitle']}</td>
                                                            <td>{provider['companyName']}</td>
                                                            <td>{provider['workFor'] == USER_ROLE.SOLUTION_PROVIDER ? 'Solution Provider' : provider['workFor'] == USER_ROLE.USER ? 'Utility' : 'Other'}</td>
                                                            <td>
                                                                <div className="d-flex gap-10 action-btns">
                                                                    {
                                                                        (provider?.isApproved == 0) &&
                                                                        <>
                                                                            <NavLink title='Approve' to="javascript:void(0)" className="view" onClick={() => approvalProvider(provider, 1)}>
                                                                                <i data-icon="S" className="icon"></i>
                                                                            </NavLink>
                                                                            <NavLink title='Reject' to="javascript:void(0)" className="delete" onClick={() => approvalProvider(provider, 2)}>
                                                                                <i data-icon="9" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }



                                                                    {
                                                                        (provider?.isApproved == 1) &&
                                                                        <>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span className='badge badge-success'> Approved</span>
                                                                            </div>
                                                                            <NavLink title='Reject' to="javascript:void(0)" className="delete" onClick={() => approvalProvider(provider, 2)}>
                                                                                <i data-icon="9" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (provider?.isApproved == 2) &&
                                                                        <>
                                                                            <div className='d-flex align-items-center'>
                                                                                <span className='badge badge-danger'>  Rejected</span>
                                                                            </div>
                                                                            <NavLink title='Approve' to="javascript:void(0)" className="view" onClick={() => approvalProvider(provider, 1)}>
                                                                                <i data-icon="S" className="icon"></i>
                                                                            </NavLink>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-10 action-btns">
                                                                    <NavLink
                                                                        title='Edit'
                                                                        to="javascript:void(0)" className="edit" onClick={() => editProvider(provider)}>
                                                                        <i className="dripicons-pencil"></i>
                                                                    </NavLink>
                                                                    <NavLink
                                                                        title='Edit Role'
                                                                        to="javascript:void(0)" className="edit" onClick={() => editRoleProvider(provider)}>
                                                                        <LuUserCog className='text-white' />
                                                                    </NavLink>
                                                                    <NavLink
                                                                        title='Delete'
                                                                        to="javascript:void(0)" className="delete" onClick={() => deleteProvider(provider)}>
                                                                        <i className="dripicons-trash"></i>
                                                                    </NavLink>

                                                                    <NavLink title='Send Email Verification'
                                                                        to="javascript:void(0)" className="edit" onClick={() => resendEmailVerificationFn(provider)}>
                                                                        <i className="dripicons-arrow-up"></i>
                                                                    </NavLink>

                                                                    {/* {
                                                                        provider?.isApproved && <NavLink title='Disapprove' to="javascript:void(0)" className="delete" onClick={() => approvalProvider(provider, false)}>
                                                                            <i data-icon="9" className="icon"></i>
                                                                        </NavLink> ||
                                                                        <NavLink
                                                                            title='Approve'
                                                                            to="javascript:void(0)" className="delete" onClick={() => approvalProvider(provider, true)}>
                                                                            <i data-icon="S" className="icon"></i>
                                                                        </NavLink>
                                                                    } */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>

                                        {
                                            providers.total > 1 &&
                                            <CustomPagination
                                                className='float-right'
                                                totalItems={providers.total}
                                                itemsPerPage={pagination.total}
                                                currentPage={pagination.page}
                                                onPageChange={handlePageChange}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                showModal && (
                    <CustomModal isShow={showModal} title={selectedProvider ? 'Update Solution Provider' : 'Add Solution Provider'} handleClose={closeModal} showFooter={false}>
                        <AddUpdateSolutionProvider providerDetails={selectedProvider} callBack={fetchProviders} closeModal={closeModal} />
                    </CustomModal>
                ) || ''
            }

            {
                showRoleModal && (
                    <CustomModal isShow={showRoleModal} title={'Update Role'} handleClose={closeRoleModal} showFooter={false}>
                        <UpdateRole providerDetails={selectedProvider} callBack={fetchProviders} closeModal={closeRoleModal} />
                    </CustomModal>
                ) || ''
            }

            {/* {
                showConfirmModal && (
                    <CustomAlert 
                        isShow={showConfirmModal} 
                        title='Delete Confirmation' 
                        handleClose={closeModal} 
                        content={`Are you sure to delete the solution provider, <strong>${ selectedProvider && (selectedProvider?.firstName +' '+selectedProvider?.lastName) || ''}</strong>?`}
                        footerButtonYes={true}
                        footerButtonYesText={'Yes, delete it'}
                        footerButtonYesAction={() => handleProviderDelete()}
                        footerButtonYesClass={'btn btn-danger ml-2'}
                        footerButtonNo={true}
                        footerButtonNoText={'Cancel'}
                        footerButtonNoAction={() => closeModal()}
                        footerButtonNoClass={'btn btn-secondary'}
                        showLoading={ isButtonLoading }
                    />
                ) || ''
            } */}
        </div>
    )
}

export default SolutionProviderList;
// export const API_BASE_URL = 'https://routefinder-dev.dreamztesting.com/API' //DEV
// export const API_BASE_URL = 'https://hangardevapi.azurewebsites.net' // LIVE
// export const API_BASE_URL = 'https://hangardevapipge.azurewebsites.net' // PGE 
// export const API_BASE_URL = 'https://hangarfortisapi.azurewebsites.net'  // FORTIS
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const URLS = {
    HOME: '/',
    REPORT: '/report',
    DASHBOARD: '/dashboard',
    
    PINS:{
        LIST: '/pins',
        ADD: '/pins/add',
        DETAILS: '/pins/view/:id',
        EDIT: '/pins/edit/:id',
    }
}

export const API_URLS = {
    USER: {
        LOGIN: `${API_BASE_URL}/api/AdminLoginAPI/Login`,
        FORGET_PASSWORD: `${API_BASE_URL}/api/UserLoginAPI/ForgotPassword`,
        FETCH_USER_BY_EMAIL: `${API_BASE_URL}/api/UserLoginAPI/GetEmailById`,
        FORGET_PASSWORD_RESET: `${API_BASE_URL}/api/UserLoginAPI/ForgotPasswordReset`,
        LOGOUT: `${API_BASE_URL}/users/logout`,
        RESEND_EMAIL_VERIFICATION: `${API_BASE_URL}/api/UserLoginAPI/SendEmailVerification`,
    },
    PIN: {
        ADD: `${API_BASE_URL}/api/PinsAPI/InsertPins`,
        EDIT: `${API_BASE_URL}/api/PinsAPI/UpdatePins`,
        FETCH_SINGLE: `${API_BASE_URL}/api/PinsAPI/EditPin`,
        LIST: `${API_BASE_URL}/api/PinsAPI/GetAllPins`,
        UPDATE_APPROVED_STATUS: `${API_BASE_URL}/api/PinsAPI/UpdatePinAcceptance`,
        DELETE: `${API_BASE_URL}/api/PinsAPI/DeletePin`,
        COMMENTS: `${API_BASE_URL}/api/PinsAPI/GetPinComments`,
        DELETE_COMMENT: `${API_BASE_URL}/api/PinsAPI/DeletePinComments`, 
        PIN_REVIEW: `${API_BASE_URL}/api/PinsAPI/PinReview`,  
        GET_PIN_REVIEW: `${API_BASE_URL}/api/PinsAPI/GetPinReviews`, 
        PIN_BULK_UPLOAD: `${API_BASE_URL}/api/PinsAPI/BulkFileUpload`,
    },
    SOLUTION_PROVIDER: {
        ADD: `${API_BASE_URL}/api/UserLoginAPI/Signup`,
        ADD_PROFILE: `${API_BASE_URL}/api/UserProfileAPI/InsertUserProfile`,
        EDIT: `${API_BASE_URL}/api/UserProfileAPI/UpdateUserProfile`,
        DELETE: `${API_BASE_URL}/api/UserAPI/DeleteUser`,
        APPROVAL: `${API_BASE_URL}/api/UserAPI/ApproveDisapproveUser`,
        LIST: `${API_BASE_URL}/api/UserAPI/GetAllUsers`,
        FETCH_PROFILE: `${API_BASE_URL}/api/AdminUserProfileAPI/GetUserProfile`,
        FETCH_PROFILE_CATEGORY_MAP: `${API_BASE_URL}/api/AdminUserProfileAPI/GetProfileCategoryMap`,
    },
    CATEGORY: {
        LIST: `${API_BASE_URL}/api/AdminCategoriesAPI/GetCategories`,
        ADD: `${API_BASE_URL}/api/AdminCategoriesAPI/InsertCategories`,
        UPDATE: `${API_BASE_URL}/api/AdminCategoriesAPI/UpdateCategories`,
        DELETE: `${API_BASE_URL}/api/AdminCategoriesAPI/DeleteCategory`,
    },
    SUB_CATEGORY: {
        ADD: `${API_BASE_URL}/api/AdminCategoriesAPI/InsertSubCategories`,
        UPDATE: `${API_BASE_URL}/api/AdminCategoriesAPI/UpdateSubCategories`,
        DELETE: `${API_BASE_URL}/api/AdminCategoriesAPI/DeleteSubCategory`,
    },
    SETTINGS: `${API_BASE_URL}/app/settings`,
    REPORT: `${API_BASE_URL}/report`,
    TABLE_DATA: `${API_BASE_URL}/app/tabledata`,
}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: 'SAGA_ACTIONS.USER.LOGIN',
        FORGET_PASSWORD: 'SAGA_ACTIONS.USER.FORGET_PASSWORD',
        FETCH_USER_BY_EMAIL: 'SAGA_ACTIONS.USER.FETCH_USER_BY_EMAIL',
        FORGET_PASSWORD_RESET: 'SAGA_ACTIONS.USER.FORGET_PASSWORD_RESET',
        LOGOUT: 'SAGA_ACTIONS.USER.LOGOUT',
        RESEND_EMAIL_VERIFICATION: 'SAGA_ACTIONS.USER.RESEND_EMAIL_VERIFICATION',
        
    },
    PIN: {
        ADD: 'SAGA_ACTIONS.PIN.ADD',
        EDIT: 'SAGA_ACTIONS.PIN.EDIT',
        FETCH_SINGLE: 'SAGA_ACTIONS.PIN.FETCH_SINGLE',
        LIST: 'SAGA_ACTIONS.PIN.LIST',
        UPDATE_APPROVED_STATUS: 'SAGA_ACTIONS.PIN.UPDATE_APPROVED_STATUS',
        DELETE: 'SAGA_ACTIONS.PIN.DELETE',
        COMMENTS: 'SAGA_ACTIONS.PIN.COMMENTS',
        DELETE_COMMENT: 'SAGA_ACTIONS.PIN.DELETE_COMMENT',
        PIN_REVIEW: 'SAGA_ACTIONS.PIN.PIN_REVIEW',
        GET_PIN_REVIEW: 'SAGA_ACTIONS.PIN.GET_PIN_REVIEW',
        PIN_BULK_UPLOAD: 'SAGA_ACTIONS.PIN.PIN_BULK_UPLOAD',
    },
    SOLUTION_PROVIDER: {
        ADD: 'SAGA_ACTIONS.SOLUTION_PROVIDER.ADD',
        ADD_PROFILE: 'SAGA_ACTIONS.SOLUTION_PROVIDER.ADD_PROFILE',
        EDIT: 'SAGA_ACTIONS.SOLUTION_PROVIDER.EDIT',
        DELETE: 'SAGA_ACTIONS.SOLUTION_PROVIDER.DELETE',
        APPROVAL: 'SAGA_ACTIONS.SOLUTION_PROVIDER.APPROVAL',
        LIST: 'SAGA_ACTIONS.SOLUTION_PROVIDER.LIST',
        FETCH_PROFILE: 'SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE',
        FETCH_PROFILE_CATEGORY_MAP: 'SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE_CATEGORY_MAP',
    },
    CATEGORY: {
        LIST: 'SAGA_ACTIONS.CATEGORY.LIST',
        ADD: 'SAGA_ACTIONS.CATEGORY.ADD',
        UPDATE: 'SAGA_ACTIONS.CATEGORY.UPDATE',
        DELETE: 'SAGA_ACTIONS.CATEGORY.DELETE',
    },
    SUB_CATEGORY: {
        ADD: 'SAGA_ACTIONS.SUB_CATEGORY.ADD',
        UPDATE: 'SAGA_ACTIONS.SUB_CATEGORY.UPDATE',
        DELETE: 'SAGA_ACTIONS.SUB_CATEGORY.DELETE',
    },
    SETTINGS: 'SAGA_ACTIONS.SETTINGS',
    REPORT: 'SAGA_ACTIONS.REPORT',
    TABLE_DATA: 'SAGA_ACTIONS.TABLE_DATA'
}

export const PinImageType = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'image/gif': ['.gif'],
    'image/svg': ['.svg'],
    // 'image/mp4': ['.mp4'],
    // 'image/webm': ['.webm'],
    // 'image/mp3': ['.mp3'],
    // 'image/wav': ['.wav'],
    // 'image/ogg': ['.ogg'],
    // 'image/glb': ['.glb'],
    // 'image/gltf': ['.gltf']
}

export const RegExp = {
    phoneRegExp: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    PHONE_NUMBER_MASK: ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
}

export const USER_ROLE = {
    ADMIN: 1,
    USER: 2,
    SOLUTION_PROVIDER: 3 // business user
}


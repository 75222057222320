import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { ForgotPasswordFormValidate } from '../../../resources/form-validator'
import { ForgotPasswordFormI } from '../../../resources/form-props'
import { useUserApi } from '../../../resources/hooks/api/userApiHook'
import FormError from '../../../components/FormError';

interface Props {

}

const ForgotPassword: React.FC<Props> = () => {
    
    const navigate = useNavigate();
    const { forgetPassword } = useUserApi();
    const [loading, setLoading] = useState<boolean>(false);

    const { control, register, handleSubmit, reset, formState: { errors } } = useForm<ForgotPasswordFormI>({
        defaultValues: {
            email: ''
        },
        resolver: yupResolver(ForgotPasswordFormValidate)
    });

    const onSubmit = ( data: any ) => {
        const params = {
            ...data,
            changePasswordBaseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/reset-password`
        }
        setLoading(true);
        forgetPassword(params, (message: string, resp: any) => {
            setLoading(false);
            reset();
            navigate('/login');
            toast.success(message)
        }, (message: string, resp: any) => {
            setLoading(false);
            toast.error(message)
        })
    }


    return (
        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-6">
                        <div className="card overflow-hidden">
                            <div className="bg-soft-primary bg-custom-primary">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-white p-4">
                                            <h5 className="text-white"> Reset Password</h5>
                                            <p>Re-Password with The Hangar.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0"> 
                                <div>
                                    <a href="javascript:void(0);">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="/assets/images/logo-icon.png" alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                
                                <div className="p-2">
                                    <div className="alert alert-success text-center mb-4" role="alert">
                                        Enter your Email and instructions will be sent to you!
                                    </div>
                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                        {/* {console.log('error: ', errors)} */}
                                        <div className="form-group">
                                            <label htmlFor="useremail">Email</label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email" { ...register('email') } />
                                            {
                                                errors && errors['email'] && errors['email'].message && (
                                                    <Form.Text className="text-danger">
                                                        {errors['email'].message}
                                                    </Form.Text>
                                                )
                                            }
                                        </div>
                    
                                        <div className="form-group row mb-0">
                                            <div className="col-12 text-right">
                                                <button className="btn btn-lg btn-primary w-md waves-effect waves-light" type="submit" disabled={loading}>
                                                    {
                                                        loading ? <Spinner animation="border" size="sm" /> : 'Reset'
                                                    }
                                                </button>
                                            </div>
                                        </div>
    
                                    </form>
                                </div>
            
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <p>Remember It ? <Link to={"/login"} className="font-weight-medium text-primary">
                                Sign In here
                            </Link> </p>
                            <p>© <script>document.write(new Date().getFullYear())</script> The Hangar.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
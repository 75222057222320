import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { usePinApi } from '../../../resources/hooks/api/pinApiHook';
import { useSelector } from 'react-redux'
import { getUser } from '../../../store/user/selectors';

interface FormValues {
    comment: string;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Note is required"),
});

interface IncomingProps {
    show: boolean;
    close: () => void;
    pinDetails: any;
    decisionStatus: any;
    callbackFuncs: any;
}

const DecisionMakingModal = ({ show, close, pinDetails, decisionStatus, callbackFuncs }: IncomingProps) => {
    const { pinReview } = usePinApi();

    const [loading, setLoading] = useState<boolean>(false) 
    const profileDetails = useSelector(getUser);

    // console.log('profileDetails', profileDetails);
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            comment: "",
        },
    });

    const onSubmit = (data: any) => {
        console.log('data', typeof (data))
        let params: any = {
            "pinsId": pinDetails?.pinsId,
            "reviewStatus": decisionStatus,
            "comment": data?.comment,
            "reviewedBy": profileDetails?.userId,
        }

        pinReview(params, (message: string, resp: any) => {
            reset()
            close()
            toast.success(message)
            callbackFuncs();
        }, (message: string, resp: any) => {
            toast.error(message)
        })


    }




    return (
        <React.Fragment>
            <Modal
                className={'salesforce-modal modal-lndscp ' }
                show={show}
            // onHide={() => { close(); reset() }}
            >
                <div className="modal-header custom-modal-header">
                    <div className="d-flex flex-wrap">
                        <h5 className="card-title mr-2">Decision Making Note</h5>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => { close(); reset() }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    {loading ?
                        <div>
                            Loading......
                        </div>
                        :
                        <div className="row">
                            <form onSubmit={handleSubmit(onSubmit)} className="w-100" >
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="comment">Note <span className='text-danger'>*</span></label>
                                                    <Controller
                                                        name={"comment"}
                                                        control={control}
                                                        render={({ field: { value, onChange, onBlur, name, ref } }) => (
                                                            <textarea
                                                                className={"form-control " + (errors?.comment ? ' border-danger' : '')}
                                                                id="comment"
                                                                name={name}
                                                                onChange={onChange}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                ref={ref}
                                                                placeholder="Enter comment..."
                                                                rows={5}
                                                            />
                                                        )}
                                                    />
                                                    {errors && errors.comment &&
                                                        <span className='text-danger'>{errors?.comment?.message}</span>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12">
                                    <div className='align-items-center d-flex gap-10 justify-content-center'>
                                        <button type='submit' className='btn btn-primary'>Save</button>
                                        <button
                                            type='button'
                                            className='btn btn-outline-danger'
                                            onClick={() => { close(); reset() }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default DecisionMakingModal

import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import './index.css'

interface CustomPaginationI {
    totalItems: number, 
    itemsPerPage: number, 
    currentPage: number, 
    className?: string,
    onPageChange: ( data: number ) => void
}
export const CustomPagination = ({ totalItems, itemsPerPage, currentPage, className = '', onPageChange }: CustomPaginationI) => {

    const [pages, setPages] = useState<number[]>([])
    const totalPages = Math.ceil(totalItems / itemsPerPage);
  
    // Generate an array of page numbers based on total pages
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    useEffect(() => {
      var calculatedPages = [];

      if ( totalPages > 4 ) {
        if ( ![1, 2].includes(Math.ceil(totalPages/2) - 2) ) {
          calculatedPages.push((Math.ceil(totalPages/2) - 2));
        }
        if ( ![1, 2].includes(Math.ceil(totalPages/2) - 1) ) {
          calculatedPages.push((Math.ceil(totalPages/2) - 1));
        }
        calculatedPages.push(Math.ceil(totalPages/2));
        if ( ![totalPages, totalPages - 1].includes(Math.ceil(totalPages/2) + 1) ) {
          calculatedPages.push(Math.ceil((totalPages/2) + 1));
        }
        if ( ![totalPages, totalPages - 1].includes(Math.ceil(totalPages/2) + 2) ) {
          calculatedPages.push(Math.ceil((totalPages/2) + 2));
        }
        
        if ( calculatedPages.length ) {
          setPages(calculatedPages);
        }
        
      }
      
    }, [totalPages])

    useEffect(() => {
      var calculatedPages = [];
      if ( totalPages > 9 ) {
        if ( (currentPage - 2) > 0 && (currentPage - 2) <= totalPages && ![1, 2, totalPages - 1, totalPages].includes(currentPage - 2) ) {
          calculatedPages.push(currentPage - 2);
        }
        if ( (currentPage - 1) > 0 && (currentPage - 1) <= totalPages && ![1, 2, totalPages - 1, totalPages].includes(currentPage -1) ) {
          calculatedPages.push(currentPage - 1);
        }
        if ( currentPage > 0 && currentPage <= totalPages && ![1, 2, totalPages - 1, totalPages].includes(currentPage) ) {
          calculatedPages.push(currentPage);
        }
        if ( (currentPage + 1) > 0 && (currentPage + 1) <= totalPages && ![1, 2, totalPages - 1, totalPages].includes(currentPage + 1) ) {
          calculatedPages.push(currentPage + 1);
        }
        if ( (currentPage + 2) > 0 && (currentPage + 2) <= totalPages && ![1, 2, totalPages - 1, totalPages].includes(currentPage + 2) ) {
          calculatedPages.push(currentPage + 2);
        }

        if ( calculatedPages.length ) {
          setPages(calculatedPages);
        }
        
      }
    }, [currentPage])
  
    return (
      <Pagination className={className}>
        {/* <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} /> */}
        <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
        <Pagination.Item
            key={1}
            active={1 === currentPage}
            activeLabel={''}
            onClick={() => onPageChange(1)}
          >
            1
        </Pagination.Item>
        {
          totalPages > 1 && 
          <Pagination.Item
              key={2}
              active={2 === currentPage}
              activeLabel={''}
              onClick={() => onPageChange(2)}
            >
              2
          </Pagination.Item> || <></>
        }
        {
          totalPages > 9 && !pages.includes(3) && <Pagination.Ellipsis className='pagination-ellipsis' /> || <></>
        }
        {/* <Pagination.Ellipsis /> */}
  
        {/* {pageNumbers.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            activeLabel={''}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        ))} */}

        {pages.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            activeLabel={''}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        ))}

        {
         totalPages > 9 && !pages.includes(totalPages - 2) && <Pagination.Ellipsis className='pagination-ellipsis' /> || <></>
        }
        {
          totalPages > 3 && 
          <Pagination.Item
            key={totalPages-1}
            active={totalPages - 1 === currentPage}
            activeLabel={''}
            onClick={() => onPageChange(totalPages - 1)}
          >
            {totalPages - 1}
        </Pagination.Item> || <></>
        }
        {
          totalPages > 2 && 
          <Pagination.Item
              key={totalPages}
              active={totalPages === currentPage}
              activeLabel={''}
              onClick={() => onPageChange(totalPages)}
            >
              {totalPages}
          </Pagination.Item> || <></>
        }
        <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        {/* <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} /> */}
      </Pagination>
    );
  };
  
import { ReactNode, ReactChildren, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../../resources/hooks/authHook";
import { useSelector } from 'react-redux';

import { useLocalStorage } from "../../../resources/hooks/localStorageHook";
import { getUser, getAuthenticated } from '../../../store/user/selectors'

export const NonProtectedLayout = ({ ...props }) => {

  const [user, setUser] = useLocalStorage("user", "");
  const checkAuthenticated = useSelector(getAuthenticated);
  const data = useAuth();
  console.log('non data: ', data, ' checkAuthenticated: ', checkAuthenticated)

  // if ( data && data.user && data.isAuthenticated ) {
  // if ( checkAuthenticated ) {
  if ( user ) {
    return <Navigate to="/dashboard" />;
  } else {
    return <Outlet />
  }
  
};
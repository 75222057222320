import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { useLocalStorage } from "../../../resources/hooks/localStorageHook";
import { LoginFormValidate } from '../../../resources/form-validator'
import { LoginFormI } from '../../../resources/form-props'
import { useUserApi } from '../../../resources/hooks/api/userApiHook'

// import { CustomModal } from '../../../components/index'
import { Spinner } from 'react-bootstrap';

interface Props {
  
}

const Login: React.FC<Props> = () => {
  // const value = useSelector(getValue)
  // const dispatch = useDispatch()

  const navigate = useNavigate();

  const [user, setUser] = useLocalStorage("user", null);
  const { login } = useUserApi();

  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [titleModal, setTitleModal] = useState<string>('Title')
  const [contentModal, setContentModal] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { register, control, handleSubmit, formState: { errors } } = useForm<LoginFormI>({
    defaultValues: {
      roleId: 1,
      email: '',
      password: ''
    },
    resolver: yupResolver(LoginFormValidate)
  });

  const closeModal = () => {
    setIsShowModal(false)
    setContentModal('')
    setTitleModal('')
  }

  const onSubmit = (data: any) => {
    setLoading(true);
    login(data, (message: string, resp: any) => {
      setLoading(false);
      toast.success(message)
      setUser(resp.data)
      navigate("/dashboard");
    }, (message: string, resp: any) => {
      setLoading(false);
      toast.error(message)
      // toast.error(message)
      // setTitleModal('Login Error')
      // setContentModal(message)
      // setIsShowModal(true)
    })
  }

  return (
    <div>
      

        <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-6">
                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-custom-primary">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-white p-4">
                                            <h5 className="text-white">Welcome Back !</h5>
                                            <p>Sign in to continue to The Hangar.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0"> 
                                <div>
                                    <a href="javascript:void(0);">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="/assets/images/logo-icon.png" alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                <div className="p-2">
                                    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        
                                        <div className="form-group">
                                            <label htmlFor="username">Username</label>
                                            <input type="text" {...register("email")} className="form-control" id="username" placeholder="Enter username" />
                                            {
                                              errors && errors['email'] && errors['email'].message && (
                                                <Form.Text className="text-danger">
                                                  {errors['email'].message}
                                                </Form.Text>
                                              ) || '' 
                                            }
                                        </div>
                
                                        <div className="form-group">
                                            <label htmlFor="userpassword">Password</label>
                                            <input type="password" {...register("password")} className="form-control" id="userpassword" placeholder="Enter password" />
                                            {
                                              errors && errors['password'] && errors['password'].message && (
                                                <Form.Text className="text-danger">
                                                  {errors['password'].message}
                                                </Form.Text>
                                              ) || '' 
                                            }
                                        </div>
                
                                        {/* <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                            <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                        </div> */}
                                        
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-primary btn-block waves-effect waves-light" type="submit" disabled={loading}>
                                            {
                                                loading ? <Spinner animation="border" size="sm" /> : 'Log In'
                                            }
                                            </button>
                                        </div>
            

                                        <div className="mt-4 text-center">
                                           <Link to={"/forgot-password"} className="text-muted">
                                              <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                                           </Link>
                                            {/* <a href="#" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</a> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 text-center">
                            <div>
                                {/* <p>Don't have an account ? <a href="javascript:void(0);" className="font-weight-medium text-primary"> Signup now </a> </p> */}
                                <p>© <script>document.write(new Date().getFullYear())</script> Innovation Force | The Hangar.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

      {/* <CustomModal title={titleModal} isShow={isShowModal} handleClose={closeModal}>
        {contentModal}
      </CustomModal> */}

    </div>
  )
}

export default Login;
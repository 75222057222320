import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserI } from './types'

export const initialState: UserI = {
  details: {},
  isAuthenticated: false
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state: UserI, action: PayloadAction<any>) {
      state.details = action.payload
    },
    authenticateUser(state: UserI, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
  },
})

export const { reducer } = userSlice

export const { updateUser, authenticateUser } = userSlice.actions

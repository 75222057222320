import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function usePinApi() {

    const callApi = useApiCall();

    const add = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.ADD, data, onSuccess, onError);
    }
    const edit = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.EDIT, data, onSuccess, onError);
    }
    const fetchSingle = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.FETCH_SINGLE, data, onSuccess, onError);
    }
    const list = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.LIST, data, onSuccess, onError);
    }
    const updateApproveStatus = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.UPDATE_APPROVED_STATUS, data, onSuccess, onError);
    }
    const remove = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.DELETE, data, onSuccess, onError);
    }

    const comments = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.COMMENTS, data, onSuccess, onError);
    }

    const deleteComment = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.DELETE_COMMENT, data, onSuccess, onError);
    }

    const pinReview = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.PIN_REVIEW, data, onSuccess, onError);
    }
    const getPinReview = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.GET_PIN_REVIEW, data, onSuccess, onError);
    }

    const bulkFileUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.PIN.PIN_BULK_UPLOAD, data, onSuccess, onError);
    }

    return {
        add,
        edit,
        fetchSingle,
        list,
        updateApproveStatus,
        remove,
        comments,
        deleteComment,
        pinReview,
        getPinReview,
        bulkFileUpload
    }
}
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-hot-toast';
import { usePinApi } from '../../../resources/hooks/api/pinApiHook';
import { getUser } from '../../../store/user/selectors';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';
import { Link } from 'react-router-dom';



interface IncomingProps {
    show: boolean;
    close: () => void;
    callbackFuncs: any;
}

const PinUploadModal = ({ show, close, callbackFuncs }: IncomingProps) => {
    const { bulkFileUpload } = usePinApi();

    const { showLoader, hideLoader } = useAppLoader();
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadStatus, setUploadStatus] = useState<any>([])
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const profileDetails = useSelector(getUser);
    //showLoader()

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
       showLoader();
        if (selectedFile) {
            setLoading(true);            
            const formData = new FormData();
            
            formData.append('UserId', profileDetails.userId);
            formData.append('ExcelFile', selectedFile);
            

            bulkFileUpload(formData, (message: string, resp: any) => {                
                //close()                
                setUploadStatus(resp)
                toast.success(message)
                callbackFuncs();
                hideLoader();
            }, (message: string, resp: any) => {
                hideLoader();
                toast.error(message)
            })
        } else {
            toast.error('Chose a file')
            hideLoader();
        }

    };


    return (
        <React.Fragment>
            <Modal
                className={'salesforce-modal modal-lndscp '}
                show={show}
            // onHide={() => { close(); reset() }}
            >
                <div className="modal-header custom-modal-header">
                    <div className="d-flex flex-wrap">
                        <h5 className="card-title mr-2">Bulk Test Flight Upload</h5>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => { close(); }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                    <div> <Link to="/assets/download/sample.xlsx" target="_blank" download> Download Sample file <i className="dripicons-download"></i></Link></div>
                    <hr />
                        <input
                            id="upload-image-input"
                            type="file"
                            accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => setSelectedFile(e.target.files?.[0] || null)}

                        />
                         
                        

                        {uploadStatus?.data?.objSuccessUploads.length > 0 && (
                            <>
                                <hr />
                                <div className="ps-3">
                                    <h5 className='modal_body_h'>Successfully Uploaded Test Flight List</h5>
                                    <table className="table upload_modal_table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Test Flight Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadStatus?.data?.objSuccessUploads?.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{item?.pinTitle}</td>
                                                    <td><span className="badge bg-success">Success</span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        {uploadStatus?.data?.objFailedImageUploads.length > 0 && (
                            <>
                                <hr />
                                <div className="ps-3">
                                    <h5 className='modal_body_h'>Image Missing Test Flight List</h5>
                                    <table className="table upload_modal_table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Test Flight Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadStatus?.data?.objFailedImageUploads?.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{item?.pinTitle}</td>
                                                    <td>{item?.message}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}

                        {uploadStatus?.data?.objFailedUploads.length > 0 && (
                            <>
                                <hr />
                                <div className="ps-3">
                                    <h5 className='modal_body_h'>Upload Fail Test Flight List</h5>
                                    <table className="table upload_modal_table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Test Flight Title</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadStatus?.data?.objFailedUploads?.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{item?.pinTitle}</td>
                                                    <td>{item?.message}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </Modal.Body>
                    {/* <Modal.Body>
                    {loading ?
                        <div>
                            Loading......
                        </div>
                        :
                        <div className="row">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Review Comment</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resp && reviewList?.length == 0 ?
                                            <tr>
                                                <td colSpan={2}>No data found!</td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    reviewList.map((item: any, index: any) =>
                                                        <tr>
                                                            <td>{item?.comment}</td>

                                                            <td>
                                                                <b>{getPinStatus(item?.reviewStatus)}</b> by
                                                                <b> {item?.firstName} {item?.lastName}</b> on
                                                                <b> {showDate(item?.reviewDate)}</b>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </Modal.Body> */}

                    <hr />
                    <div style={{ textAlign: 'center' }}>
                        <button className="btn btn-dark" onClick={() => { close(); }}>
                            Close
                        </button>

                        <button className="btn btn-danger mx-3" type='submit'>
                            Submit
                        </button>
                    </div>
                </form>
                <br />

            </Modal>
        </React.Fragment>
    )
}

export default PinUploadModal
import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../resources/hooks/authHook";

import { useLocalStorage } from "../../../resources/hooks/localStorageHook";
import { NavLink } from 'react-router-dom';
import avater from '../../../images/avatar.jpg';

interface Props {

}

export const PageTopBar: React.FC<Props> = () => {

    const [user, setUser] = useLocalStorage("user", "");
    const data = useAuth();
    const navigate = useNavigate();

    const logout = () => {
        setUser(null);
        navigate("/login");
    }

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box">
                        <a href="javascript:void(0);" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="assets/images/logo-icon.png" alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/hangar-logo.png" alt="" height="36" />
                            </span>
                        </a>

                        <a href="javascript:void(0);" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="assets/images/logo-light.svg" alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/logo-light.png" alt="" height="19" />
                            </span>
                        </a>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-toggle="collapse" data-target="#topnav-menu-content">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                </div>

                <div className="d-flex">

                    <div className="dropdown d-none ml-2">
                        <button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="mdi mdi-magnify"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                            aria-labelledby="page-header-search-dropdown">
                
                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <img className="rounded-circle header-profile-user" src={avater} alt="Header Avatar" /> */}
                            <span className=" d-xl-inline-block ml-1" key="t-henry">Admin</span>
                            <i className="mdi mdi-chevron-down  d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <NavLink to={'/profile'} className="dropdown-item">
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i> <span key="t-profile">Profile</span>
                            </NavLink>
                            
                            {/* <div className="dropdown-divider"></div> */}
                            <a className="dropdown-item text-danger" onClick={() => logout()}><i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> <span key="t-logout">Logout</span></a>
                        </div>
                    </div>

        
                </div>
            </div>
        </header>
    )
}

import { call, put } from "redux-saga/effects";
import { updateDetails, updateList, updateListApproveStatus } from "../store/pin";
import { API_URLS } from "../resources/constants";
import {CallApi} from './callApi'

export function* add(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.PIN.ADD, payload, true);
    // yield put(updateUser(result.data));
    // action && action.callbackSuccess(result.data);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateUser(result.data.data));
      // yield put(authenticateUser(true));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* edit(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.PIN.EDIT, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* fetchSingle(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.PIN.FETCH_SINGLE, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* list(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.PIN.LIST, payload, true);
    // yield put(updateUser(result.data));
    // action && action.callbackSuccess(result.data);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateUser(result.data.data));
      // yield put(authenticateUser(true));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* remove(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.DELETE, API_URLS.PIN.DELETE, payload, true);
    // yield put(updateUser(result.data));
    // action && action.callbackSuccess(result.data);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateUser(result.data.data));
      // yield put(authenticateUser(true));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* updateApproveStatus(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.PIN.UPDATE_APPROVED_STATUS, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* comments(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.PIN.COMMENTS, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* deleteComment(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.DELETE, API_URLS.PIN.DELETE_COMMENT, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* pinReview(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.PIN.PIN_REVIEW, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* bulkFileUpload(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.PIN.PIN_BULK_UPLOAD, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}


export function* getPinReview(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.PIN.GET_PIN_REVIEW, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}




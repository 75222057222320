
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { CategoryAddEditFormValidate } from '../../../resources/form-validator'
import { CategoryFormI } from '../../../resources/form-props'
import { useCategoryApi } from '../../../resources/hooks/api/categoryApiHook'

interface Props {
    category: any,
    parentCategory: any,
    callBack: () => void,
    closeModal: () => void
}

const AddUpdateCategory: React.FC<Props> = ({ category, parentCategory = null, callBack = () => {}, closeModal }) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { addCat, addSubCat, updateCat, updateSubCat } =  useCategoryApi();

    const { register, control, handleSubmit, formState: { errors } } = useForm<CategoryFormI>({
        defaultValues: {
            categoryName: category && (category['categoryName'] || category['subCategoryName']) || ''
        },
        resolver: yupResolver(CategoryAddEditFormValidate)
    });

    const onSubmit = (data: any) => {
        if ( category ) {
            if ( parentCategory ) {
                const params = {
                    roleId: 1,
                    parentCategoryId: parentCategory['categoryId'],
                    subCategoryId: category['subCategoryId'],
                    subCategoryName: data['categoryName']
                }
                setLoading(true);
                updateSubCat(params, (message: string, resp: any) => {
                    setLoading(false);
                    closeModal();
                    callBack();
                    toast.success(message)
                }, (message: string, resp: any) => {
                    setLoading(false);
                    toast.error(message)
                })
            } else {
                const params = {
                    roleId: 1,
                    categoryId: category['categoryId'],
                    categoryName: data['categoryName']
                }
                setLoading(true);
                updateCat(params, (message: string, resp: any) => {
                    setLoading(false);
                    closeModal();
                    callBack();
                    toast.success(message)
                }, (message: string, resp: any) => {
                    setLoading(false);
                    toast.error(message)
                })
            }
        } else {
            if ( parentCategory ) {
                const params = {
                    roleId: 1,
                    parentCategoryId: parentCategory['categoryId'],
                    subCategoryName: data['categoryName']
                }
                setLoading(true);
                addSubCat(params, (message: string, resp: any) => {
                    setLoading(false);
                    closeModal();
                    callBack();
                    toast.success(message)
                }, (message: string, resp: any) => {
                    setLoading(false);
                    toast.error(message)
                })
            } else {
                const params = {
                    roleId: 1,
                    categoryName: data['categoryName']
                }
                setLoading(true);
                addCat(params, (message: string, resp: any) => {
                    setLoading(false);
                    closeModal();
                    callBack();
                    toast.success(message)
                }, (message: string, resp: any) => {
                    setLoading(false);
                    toast.error(message)
                })
            }
        }
    }

    return (
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        
            <div className="form-group">
                <label htmlFor="category_name">Category Name</label>
                <input type="text" {...register("categoryName")} className="form-control"  placeholder="Category name" id={'category_name'} />
                {
                    errors && errors['categoryName'] && errors['categoryName'].message && (
                        <Form.Text className="text-danger">
                            {errors['categoryName'].message}
                        </Form.Text>
                    ) || '' 
                }
            </div>
            
            <div className="mt-3 text-center">
                <button className="btn btn-primary" type="submit" disabled={loading}>
                    {
                        loading ? <Spinner animation="border" size="sm" /> : 'Submit'
                    }
                </button>&nbsp;
                <button className="btn btn-danger ml-2" type="button" onClick={closeModal}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default AddUpdateCategory;
import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast';
import {store, persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react';
import FullPageLoader from './components/PageLoader';

// import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App'

const Main: React.FC = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <FullPageLoader />
        <App />
        <Toaster position='top-center' />
      </PersistGate>
    </Provider>
  )
}

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<Main />);
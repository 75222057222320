import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useAuth } from "../../../resources/hooks/authHook";
import { getUser, getAuthenticated } from '../../../store/user/selectors'

import { useLocalStorage } from "../../../resources/hooks/localStorageHook";

import { PageTopBar } from "./PageTopbar";
import { RightBar } from "./RightBar";
import { PageTopNav } from "./TopNav"
import { Footer } from "./Footer";

export const ProtectedLayout = () => {

  const [user, setUser] = useLocalStorage("user", "");
  const checkAuthenticated = useSelector(getAuthenticated);
  const data = useAuth();

  // if ( data && (!data.user || !data.isAuthenticated) ) {
  // if ( checkAuthenticated ) {
    console.log('user===',user)
    console.log('data===',data)
  if ( !user ) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <div id="layout-wrapper">

        <PageTopBar />

        <PageTopNav />
        
        <div className="main-content">
            <Outlet />
            <Footer />
        </div>
        
      </div>

      <RightBar />

      <div className="rightbar-overlay"></div>
    </div>
  )
};
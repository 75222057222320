import axios from 'axios';



// axios.defaults.withCredentials = true;

export class CallApi {
  static GET(url: string, params: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      let headers: any = {
      };

      if ( isAuth ) {
        const value = window.localStorage.getItem('user');
        if (value) {
          const user = JSON.parse(value);
          headers['Authorization'] = 'Bearer ' + user['token'];
        }
      }

      return axios({url, headers, params}).then((success: any) => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response);
      });
    });
  }

  static POST(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      let headers: any = {}

      if ( isAuth ) {
        const value = window.localStorage.getItem('user');
        if (value) {
          const user = JSON.parse(value);
          headers['Authorization'] = user['token'];
        }
      }

      return axios({method: 'POST', url, headers, data})
        .then(async success => {
          return resolve(success);
          /*if ( success && success.data && success.data.success ) {
            return resolve(success.data);
          } else {
            return reject(success.data);
          }*/
        })
        .catch(error => {
          return reject(error.response);
        });
    });
  }

  static PUT(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      let headers: any = {};

      if ( isAuth ) {
        const value = window.localStorage.getItem('user');
        if (value) {
          const user = JSON.parse(value);
          headers['Authorization'] = 'Bearer ' + user['token'];
        }
      }
      
      return axios({ method: 'PUT', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response);
      });
    });
  }

  static DELETE(url: string, data: any = {}, isAuth: boolean = false) {
    return new Promise((resolve, reject) => {
      let headers: any = {};

      if ( isAuth ) {
        const value = window.localStorage.getItem('user');
        if (value) {
          const user = JSON.parse(value);
          headers['Authorization'] = 'Bearer ' + user['token'];
        }
      }

      console.log('data: ', data)
      return axios({ method: 'DELETE', url, headers, data }).then(success => {
        return resolve(success);
      }).catch(error => {
        return reject(error.response);
      });
    });
  }
}

import React, { HtmlHTMLAttributes } from 'react'
import { BiSolidLike } from 'react-icons/bi';
import { BsDownload, BsFillShieldLockFill } from 'react-icons/bs';
import { FaCheckSquare, FaEye, FaTimes } from 'react-icons/fa';
import { MdOutlineMail, MdOutlineRemoveRedEye } from 'react-icons/md';
import { LuAlarmClock, LuUpload } from "react-icons/lu";
import { IoIosArrowBack, IoIosArrowForward, IoMdCloudUpload } from "react-icons/io";
import { FaBell, FaLocationDot, FaPlugCircleCheck, FaPlugCircleXmark, FaUserLock, FaWpforms } from 'react-icons/fa6';
import { RiDeleteBin6Line, RiShieldKeyholeLine } from "react-icons/ri";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";
import { IoNotificationsOutline } from 'react-icons/io5';
import { CiEdit } from 'react-icons/ci';
import { GoPlus } from 'react-icons/go';
import { MdOutlinePhone } from "react-icons/md";


type nameType = 'delete' | 'edit' | 'download' | 'like' | 'checked' | 'clock' | 'arrow-right' | 'arrow-left' |
    'form' | 'times' | 'shield-lock' | 'shield-lock-outline' | 'file-upload' | 'unsorted' | 'sort-up' | 'sort-down' |
    'bell' | 'view' | 'notification' | 'user-lock' | 'location' | 'plug-check' | 'plug-times' | 'plus' | 'eye' |
    'upload' | 'phone' | 'mail'

interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    name: nameType;
    size?: any;
    className?: string;
    onClick?: (e: any) => void
}
const Icon: React.FC<props> = ({
    children,
    name,
    size = '24',
    className = '',
    onClick,
    ...args
}) => {


    const getIconByName = (name: nameType) => {
        switch (name) {
            case 'delete': return <RiDeleteBin6Line style={{ fontSize: `${size}px` }} />;
            case 'edit': return <CiEdit style={{ fontSize: `${size}px` }} />;
            case 'download': return <BsDownload style={{ fontSize: `${size}px` }} />;
            case 'like': return <BiSolidLike style={{ fontSize: `${size}px` }} />;
            case 'checked': return <FaCheckSquare style={{ fontSize: `${size}px` }} />;
            case 'clock': return <LuAlarmClock style={{ fontSize: `${size}px` }} />;
            case 'arrow-right': return <IoIosArrowForward style={{ fontSize: `${size}px` }} />;
            case 'arrow-left': return <IoIosArrowBack style={{ fontSize: `${size}px` }} />; 
            case 'form': return <FaWpforms style={{ fontSize: `${size}px` }} />;
            case 'times': return <FaTimes style={{ fontSize: `${size}px` }} />;
            case 'shield-lock': return <BsFillShieldLockFill style={{ fontSize: `${size}px` }} />;
            case 'shield-lock-outline': return <RiShieldKeyholeLine style={{ fontSize: `${size}px` }} />;
            case 'file-upload': return <IoMdCloudUpload style={{ fontSize: `${size}px` }} />;
            case 'sort-up': return <TiArrowSortedUp style={{ fontSize: `${size}px` }} />;
            case 'sort-down': return <TiArrowSortedDown style={{ fontSize: `${size}px` }} />;
            case 'unsorted': return <TiArrowUnsorted style={{ fontSize: `${size}px` }} />;
            case 'bell': return <FaBell style={{ fontSize: `${size}px` }} />;
            case 'notification': return <IoNotificationsOutline style={{ fontSize: `${size}px` }} />;
            case 'view': return <MdOutlineRemoveRedEye style={{ fontSize: `${size}px` }} />;
            case 'user-lock': return <FaUserLock style={{ fontSize: `${size}px` }} />;
            case 'location': return <FaLocationDot style={{ fontSize: `${size}px` }} />;
            case 'plug-check': return <FaPlugCircleCheck style={{ fontSize: `${size}px` }} />;
            case 'plug-times': return <FaPlugCircleXmark style={{ fontSize: `${size}px` }} />;
            case 'plus': return <GoPlus style={{ fontSize: `${size}px` }} />;
            case 'eye': return <FaEye style={{ fontSize: `${size}px` }} />; 
            case 'upload': return <LuUpload style={{ fontSize: `${size}px` }} />; 
            case 'phone': return <MdOutlinePhone style={{ fontSize: `${size}px` }} />;
            case 'mail': return <MdOutlineMail style={{ fontSize: `${size}px` }} />;
            default: return null;
        }
    }

    const getStyle = () => {
        let style: any = {}
        if (onClick) {
            style.cursor = 'pointer';
        }
        return style
    }

    return (
        <span onClick={onClick ? onClick : (e: any) => { }} className={`${className} ${(onClick) ? 'cursor-pointer' : ''}`}
            style={getStyle()}>
            {getIconByName(name)}
        </span>
    )
}

export default Icon;

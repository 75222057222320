import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useSelector } from 'react-redux';
import { getUser, getAuthenticated } from '../../../store/user/selectors'

import { useUserApi } from '../../../resources/hooks/api/userApiHook'

interface Props {

}

const Dashboard: React.FC<Props> = () => {
    // const value = useSelector(getValue)
    // const dispatch = useDispatch()

    const checkAuthenticated = useSelector(getAuthenticated);
    const userDetails = useSelector(getUser);

    return (
        <div className="page-content">
            
            <div className="container-fluid">
                <div className="card text-center">
                    <img src="assets/images/coming_soon.png" alt=""  />
                </div>
                
                {/* <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">Dashboard</h4>

                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-xl-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card mini-stats-wid">
                                    <div className="card-body">
                                        
                                    <span className="logo-lg">
                                        
                                    </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div> */}
                
            </div>
        </div>
    )
}

export default Dashboard;
import React from 'react'
import { useNavigate } from 'react-router-dom';
import AddEditForm from './AddEditForm';
import TitleBar from '../../../components/TitleBar';
import { URLS } from '../../../resources/constants';

interface Props {

}

const PinAdd: React.FC<Props> = () => {

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(URLS.PINS.LIST)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <TitleBar title={'Add A Test Flight'} breadcrumbs={{
                    'Test Flight': URLS.PINS.LIST,
                    'Add': '#',
                }} />


                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <AddEditForm handleCancel={handleCancel} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PinAdd;
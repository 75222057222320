
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import MaskedInput from 'react-text-mask';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

import { SolutionProviderFormValidate } from '../../resources/form-validator'
import { SolutionProviderFormI } from '../../resources/form-props'
import { useUserApi } from '../../resources/hooks/api/userApiHook'
import FormError from '../../components/FormError';
import { USER_ROLE, RegExp } from '../../resources/constants';
import { getUser } from '../../store/user/selectors'
import Select from "react-select"

interface Props {
    providerDetails: any,
    callBack: () => void,
    closeModal: () => void
}

const UpdateRole: React.FC<Props> = ({ providerDetails, callBack = () => {}, closeModal }) => {

    const loggedInUser = useSelector(getUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchedProfile, setFetchedProfile] = useState<any | null>(null);
    const { add: addProvider, edit: editProvider, addProfile, fetchProfile } =  useUserApi();

    const roles = [
        {value:2,label:'Utility User'},
        {value:3,label:'Solution Provider'},
    ]

    const { register, control, reset,setValue, handleSubmit, formState: { errors } } = useForm<SolutionProviderFormI>({
        defaultValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            jobTitle: '',
            companyName: '',
        },
        /*{
            firstName: providerDetails && providerDetails.firstName || '',
            lastName: providerDetails && providerDetails.lastName || '',
            phoneNumber: providerDetails && providerDetails.phoneNumber || '',
            email: providerDetails && providerDetails.email || ''
        },*/
        resolver: yupResolver(SolutionProviderFormValidate)
    });

    useEffect(() => {
        if ( providerDetails ) {
            const params = {
                roleId: 1,
                userProfileId: providerDetails['userProfileId']
            }
            setLoading(true);
            fetchProfile(params, (message: string, resp: any) => {
                setLoading(false);
                setFetchedProfile(resp['data'])
            }, (message: string, resp: any) => {
                setLoading(false);
                toast.error(message)
            })
        }
    }, [providerDetails])

    useEffect(() => {
        if ( fetchedProfile ) {
            console.log('fetchedProfile => ....', fetchedProfile)
            reset({
                firstName: fetchedProfile && fetchedProfile.firstName || '',
                lastName: fetchedProfile && fetchedProfile.lastName || '',
                phoneNumber: fetchedProfile && fetchedProfile.phoneNumber.substr(0, 10) || '',
                email: fetchedProfile && fetchedProfile.email || '', 
                jobTitle: fetchedProfile && fetchedProfile.jobTitle || '', 
                role:fetchedProfile && fetchedProfile.workFor || '', 
                companyName: fetchedProfile && fetchedProfile.companyName || '',
            })
        }
    }, [fetchedProfile])

    const onSubmit = (data: any) => {
        console.log('-------------', data)
        if ( providerDetails ) {
            const formData = new FormData();
            formData.append('email', data['email']);
            formData.append('UserPofileId', providerDetails['userProfileId']);
            formData.append('UserId', providerDetails['userId']);
            formData.append('FirstName', data.firstName);
            formData.append('LastName', data.lastName);
            formData.append('PhoneNumber', data.phoneNumber && data.phoneNumber.replace( /\D+/g, '' ) || '');
            // formData.append('TwoFA', fetchedProfile['twoFA']);

            formData.append('jobTitle', data.jobTitle); 
            formData.append('companyName', data.companyName);
            formData.append('workFor', data.role);

            // formData.append('jobTitle', fetchedProfile?.jobTitle);
            // formData.append('companyName', fetchedProfile?.companyName);
            formData.append('displayName', fetchedProfile?.displayName);
            formData.append('biodata', fetchedProfile?.biodata);
            formData.append('workFor', fetchedProfile?.workFor);
            formData.append('twoFA', fetchedProfile?.twoFA); 


            // providerDetails

            setLoading(true);
            editProvider(formData, (message: string, resp: any) => {
                setLoading(false);
                closeModal();
                callBack();
                toast.success(message)
            }, (message: string, resp: any) => {
                setLoading(false);
                toast.error(message)
            })
        } else {
            const params = {
                recaptchaToken: Math.random().toString(36).slice(2),
                clientIp: "130.188.67.134",
                roleId: USER_ROLE.SOLUTION_PROVIDER,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber && data.phoneNumber.replace( /\D+/g, '' ) || '',
                email: data.email,
                password: Math.random().toString(36).slice(2),
                createdBy: loggedInUser.userId,
                emailVerificationBaseURL: process.env.REACT_APP_FRONTEND_BASE_URL + '/reset-password',
                jobTitle: data.jobTitle,
                companyName: data.companyName,
            }
            setLoading(true);
            addProvider(params, (message: string, resp: any) => {
                const formData = new FormData();
                formData.append('UserId', resp['data']['userId']);
                formData.append('WorkFor', ''+USER_ROLE.SOLUTION_PROVIDER);
                formData.append('TwoFA', resp['data']['twoFA']);

                setLoading(true);
                addProfile(formData, (message: string, resp: any) => {
                    setLoading(false);
                    closeModal();
                    callBack();
                    toast.success(message)
                }, (message: string, resp: any) => {
                    setLoading(false);
                    toast.error(message)
                })
            }, (message: string, resp: any) => {
                setLoading(false);
                toast.error(message)
            })
        }
    }

    return (
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            {/* {console.log('error: ', errors)} */}
            <input type="hidden" readOnly={!!providerDetails} {...register("email")} className="form-control" maxLength={50} placeholder="Email" id={'email'} />
            <input type="hidden" {...register("firstName")} className="form-control" maxLength={50} placeholder="First name" id={'first_name'} />
            <input type="hidden" {...register("lastName")} className="form-control" maxLength={50} placeholder="Last name" id={'last_name'} />
            <Controller
                    name={"phoneNumber"}
                    control={control}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <MaskedInput
                            defaultValue={value}
                            onChange={onChange}
                            type="hidden"
                            placeholder="Phone"
                            mask={RegExp.PHONE_NUMBER_MASK}
                            className="form-control"
                            autoComplete={"new-password"} maxLength={25}
                        />
                    )}
                />
                
            <input type="hidden" {...register("jobTitle")} className="form-control" maxLength={150}placeholder="Job Title" id={'jobTitle'} />
            <input type="hidden" {...register("companyName")} className="form-control" maxLength={150} placeholder="Company Name" id={'companyName'} />
            <div className="form-group">
                <label htmlFor="role">Role<strong className='text-danger'>*</strong></label>
                <Controller
                    control={control}
                    name="role"
                    rules={{ required: "Role is required" }}
                    render={({ field: { onChange, value, ref, name } }) => (
                        <select name={name} className='form-control' onChange={(e)=>{setValue('role',e.target.value)}}>
                            <option value='' selected={true}>Select</option>
                            {roles.map((role, i) => (
                                <option value={role.value} selected={role.value == value}>{role.label}</option>
                            ))}
                        </select>

                    )}
                /> 
                                            
                <FormError errors={errors} field={'role'} />
            </div>
            
            
            <div className="mt-3 text-center">
                <button className="btn btn-primary" type="submit" disabled={loading}>
                    {
                        loading ? <Spinner animation="border" size="sm" /> : 'Submit'
                    }
                </button>&nbsp;
                <button className="btn btn-danger ml-2" type="button" onClick={closeModal}>
                    Cancel
                </button>
            </div>
        </form>
    )
}

export default UpdateRole;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import { getSolutionProviderDetails } from '../../../store/solutionProvider/selectors'

interface Props {

}
const UserProfile: React.FC<Props> = () => {

    const profileDetails = useSelector(getSolutionProviderDetails);

    return (
        <div className="profile_right user_info">
            <div className="d-flex align-items-center justify-content-between">
                <h3>User Profile</h3>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">First Name</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.firstName ? profileDetails?.firstName : '--'}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Last Name</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.lastName ? profileDetails?.lastName : '--'}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Phone Number</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.phoneNumber ? profileDetails?.phoneNumber : '--'}</label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Email Address</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.email ? profileDetails?.email : '--'}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Display Name</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.displayName ? profileDetails?.displayName : '--'}</label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Company Name</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.companyName ? profileDetails?.companyName : '--'}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">Job Title</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">{profileDetails?.jobTitle ? profileDetails?.jobTitle : '--'}</label>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-4"><label className="form-label">I Work For</label></div>
                            <div className="col-8">
                                <label className="form-label user_text">
                                {
                                    profileDetails ? (profileDetails?.workFor == '1' ? 'Utility' : profileDetails?.workFor == '2' ? 'Solution Provider' : 'Other') : 'Other'
                                }
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="mb-3">
                        <div className="d-flex">
                            <div className="w-17"><label className="form-label">Bio</label></div>
                            <div className="w-83">
                                <label className="form-label user_text">
                                    {profileDetails?.biodata ? profileDetails?.biodata : '--'}
                                </label>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile;
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

interface Props {

}

export const PageTopNav: React.FC<Props> = () => {
    return (
        <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">

                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link dropdown-toggle arrow-none" to={"/dashboard"}>
                                        <i className="dripicons-home font-size-16 mr-2"></i><span key="t-dashboards">Dashboards</span>
                                    </NavLink>
                                    {/* <a className="nav-link dropdown-toggle arrow-none" href="javascript:void(0);" id="topnav-dashboard">
                                        <i className="dripicons-home font-size-16 mr-2"></i><span key="t-dashboards">Dashboards</span>
                                    </a> */}
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle arrow-none" href="javascript:void(0);" id="topnav-dashboard" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="dripicons-user font-size-16 mr-2"></i><span key="t-dashboards">Users</span> <div className="arrow-down"></div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="topnav-dashboard">
                                        <NavLink to={"/solution-provider"} className="dropdown-item">
                                            Solution Provider
                                        </NavLink>
                                        <NavLink to={"/utility-user"} className="dropdown-item">
                                            Utility User
                                        </NavLink>
                                        {/* <NavLink to={"/solution-provider"} className="dropdown-item">
                                            Admin Employee
                                        </NavLink> */}

                                        {/* <a href="javascript:void(0);" className="dropdown-item" key="t-default_1">Solution Provider</a>
                                    
                                        <a href="javascript:void(0);" className="dropdown-item" key="t-default_2">Admin Employee</a> */}
                                    </div>
                                </li>

                                <li className="nav-item dropdown">
                                    <NavLink to={"/categories"} className="nav-link dropdown-toggle arrow-none">
                                        <i className="dripicons-basket font-size-16 mr-2"></i><span key="t-dashboards">Category</span>
                                    </NavLink>
                                    {/* <a className="nav-link dropdown-toggle arrow-none" href="javascript:void(0);" id="topnav-dashboard">
                                        <i className="dripicons-basket font-size-16 mr-2"></i><span key="t-dashboards">Category</span>
                                    </a> */}
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink to={"/pins"} className="nav-link dropdown-toggle arrow-none">
                                        <i className="dripicons-pin font-size-16 mr-2"></i><span key="t-dashboards">Test Flight</span>
                                    </NavLink>
                                    {/* <a className="nav-link dropdown-toggle arrow-none" href="javascript:void(0);" id="topnav-dashboard">
                                        <i className="dripicons-pin font-size-16 mr-2"></i><span key="t-dashboards">Test Flight</span>
                                    </a> */}
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle arrow-none" href="javascript:void(0);" id="topnav-dashboard">
                                        <i className="dripicons-gear font-size-16 mr-2"></i><span key="t-dashboards">Setting</span>
                                    </a>
                                </li> */}
                                
                                
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
    )
}
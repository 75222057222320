
import { call, put } from "redux-saga/effects";
import { updateDetails, updateList, updateListApproveStatus } from "../store/pin";
import { API_URLS } from "../resources/constants";
import {CallApi} from './callApi'

export function* list(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.GET, API_URLS.CATEGORY.LIST, payload, true);
    // yield put(updateUser(result.data));
    // action && action.callbackSuccess(result.data);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateUser(result.data.data));
      // yield put(authenticateUser(true));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* addCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.CATEGORY.ADD, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* updateCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.CATEGORY.UPDATE, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* addSubCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.POST, API_URLS.SUB_CATEGORY.ADD, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* updateSubCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.PUT, API_URLS.SUB_CATEGORY.UPDATE, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* deleteCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.DELETE, API_URLS.CATEGORY.DELETE, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}

export function* deleteSubCat(action: any): any {
  try {
    const payload = action.payload;
    let result = yield call(CallApi.DELETE, API_URLS.SUB_CATEGORY.DELETE, payload, true);
    if ( result['data'] && result['data']['success'] ) {
      // yield put(updateListApproveStatus(result.data.data));
      action && action.callbackSuccess(result.data);
    } else {
      action && action.callbackError(result['data']);
    }
  } catch (e) {
    action && action.callbackError(e);
  }
}



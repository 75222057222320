import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PinI } from './types'

export const initialState: PinI = {
  details: {},
  list: []
}

const pinSlice = createSlice({
  name: 'pin',
  initialState,
  reducers: {
    updateDetails(state: PinI, action: PayloadAction<any>) {
      state.details = action.payload
    },
    updateList(state: PinI, action: PayloadAction<any>) {
      state.list = action.payload
    },
    updateListApproveStatus(state: PinI, action: PayloadAction<any>) {
      const pins = state.list;
      const gotIndex = pins.findIndex((e : any, index: number) => e['id'] == action.payload.id);

      if ( gotIndex < 0 ) {
        
      } else {
        const data = pins[gotIndex];
        state.list = pins.splice(gotIndex, 1, data);
      }
    },
  },
})

export const { reducer } = pinSlice

export const { updateDetails, updateList, updateListApproveStatus } = pinSlice.actions

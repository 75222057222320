import moment from "moment"
import Icon from "../../components/Icon"


export const formatNumber = (num: number) => {
  if (num || num === 0) {
    return (Math.floor(Number(num) * 100) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return '0.00'
}
export const USPriceFormat = (num: number) => {
  if (num || num === 0) {
    return (Math.floor(Number(num)) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return '0.00'
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const fetchPageNumbers = (totalPages: number, currentPage: number) => {
  //console.log(pageNeighbours)
  let pageNumbers = []

  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i)
    }
  } else {
    // Always print first page button
    pageNumbers.push(1)

    // Print "..." only if currentPage is > 3
    if (currentPage > 3) {
      pageNumbers.push('...')
    }

    // special case where last page is selected...
    if (currentPage == totalPages) {
      pageNumbers.push(currentPage - 2)
    }

    // Print previous number button if currentPage > 2
    if (currentPage > 2) {
      pageNumbers.push(currentPage - 1)
    }

    //Print current page number button as long as it not the first or last page
    if (currentPage != 1 && currentPage != totalPages) {
      pageNumbers.push(currentPage)
    }

    //print next number button if currentPage < lastPage - 1
    if (currentPage < totalPages - 1) {
      pageNumbers.push(currentPage + 1)
    }

    // special case where first page is selected...
    if (currentPage == 1) {
      pageNumbers.push(currentPage + 2)
    }

    //print "..." if currentPage is < lastPage -2
    if (currentPage < totalPages - 2) {
      pageNumbers.push('...')
    }

    //Always print last page button if there is more than 1 page
    pageNumbers.push(totalPages)
  }
  return pageNumbers
}



export const dateAgo = (dateIncome: string) => {
  var today = new Date();

  const date1 = new Date(dateIncome);
  const date2 = new Date(today);
  var diffDays = date2.getDate() - date1.getDate();

  console.log(diffDays + " days");
  if (diffDays > 0) {
    return diffDays + " days ago"
  } else {
    return 'Few time ago'
  }
}


export const showDate = (dbDate: any, dateFormat: string = '') => {
  if (dateFormat == '') {
    dateFormat = 'MM-DD-YYYY';
  }
  return (dbDate !== '') ? moment(dbDate).format(dateFormat) : "";
}

export const getSortIcon = (sort: string, sortBy: string, sortByField: string,) => {
  if (sortBy == sortByField && sort == 'asc') {
    return <Icon name="sort-up" />
  } else if (sortBy == sortByField && sort == 'desc') {
    return <Icon name="sort-down" />
  }
}

export const replaceUrlParams = (url: string, params: any) => {
  Object.keys(params).map((param) => {
      url = url.replace(`:${param}`, params[param])
  })
  return url
}

import React, { useState } from 'react'

interface Props {

}

export const Footer: React.FC<Props> = () => {
    return (
        <footer className="footer">
            <div className="text-center">
                <div>
                    <p>© <script>document.write(new Date().getFullYear())</script> Innovation Force | The Hangar.</p>
                </div>
            </div>
            {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <script>document.write(new Date().getFullYear())</script> © Skote.
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-right d-none d-sm-block">
                            Design & Develop by Themesbrand
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    )
}
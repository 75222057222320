import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { NavLink } from 'react-router-dom';
import * as yup from "yup";
import FormError from '../../../components/FormError';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Props } from 'react-select';
import { getUser } from '../../../store/user/selectors';
import { Spinner } from 'react-bootstrap';
import { useUserApi } from '../../../resources/hooks/api/userApiHook';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';

interface formProps {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
}
const FormValidate = yup.object().shape({
    firstName: yup.string().required('Firstname required'),
    lastName: yup.string().required('Lastname required'),
    email: yup.string().required('Email required'),
    phoneNumber: yup.string().required('Phone Number required'),
})


const AdminProfile: React.FC<Props> = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/dashboard')
    }

    const { showLoader, hideLoader } = useAppLoader();
    // const [user, setUser] = useLocalStorage("user", null);
    const profileDetails = useSelector(getUser);
    console.log('profileDetails', profileDetails)
    const { fetchProfile, edit } = useUserApi();
    const [userData, setUserData] = useState<boolean>(false);

    const { register, control, reset, handleSubmit, getValues, setValue, setError, clearErrors, formState: { errors } } = useForm<formProps>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
        },
        resolver: yupResolver(FormValidate)
    });

    const getProfileData = () => {
        showLoader();
        var params = {
            roleId: profileDetails?.roleId,
            userProfileId: profileDetails?.userProfileId
        }
        fetchProfile(params, (message: string, resp: any) => {
            console.log('resp.data', resp.data)
            setUserData(resp.data)


            setValue('firstName', resp.data?.firstName)
            setValue('lastName', resp.data?.lastName)
            setValue('email', resp.data?.email)
            setValue('phoneNumber', resp.data?.phoneNumber)
            hideLoader();
        }, (message: string, resp: any) => {
            hideLoader();
            toast.error(message)
        })

    }


    useEffect(() => {
        getProfileData();
    }, [])

    const onSubmit = (data: any) => {
        const formData: any = new FormData();
        formData.append("FirstName", data.firstName);
        formData.append("LastName", data.lastName);
        formData.append("PhoneNumber", data.phoneNumber);

        formData.append("Email", profileDetails?.email);
        formData.append("UserPofileId", profileDetails?.userProfileId);
        formData.append("UserId", profileDetails?.userId);
        formData.append("TwoFA", profileDetails?.twoFA);


        edit(formData, (message: string, resp: any) => {
            console.log('resp.data', resp.data)
            toast.success(message)
        }, (message: string, resp: any) => {
            toast.error(message)
        })

    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">Manage Profile</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <NavLink to={'/dashboard'}>Dashboard</NavLink>
                                    </li>
                                    <li className="breadcrumb-item active">Manage Profile</li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* {console.log('errors: ', errors)} */}
                                <div className="profile_page">

                                    <div className="profile_right">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3>Manage Profile</h3>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">First Name<strong className='text-danger'>*</strong></label>
                                                    <input type="text" className="form-control" {...register('firstName')} />
                                                    <FormError errors={errors} field={'firstName'} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Last Name<strong className='text-danger'>*</strong></label>
                                                    <input type="text" className="form-control" {...register('lastName')} />
                                                    <FormError errors={errors} field={'lastName'} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Email<strong className='text-danger'>*</strong></label>
                                                    <input type="text" className="form-control" {...register('email')} readOnly />
                                                    <FormError errors={errors} field={'email'} />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Phone Number<strong className='text-danger'>*</strong></label>
                                                    <input type="text" className="form-control" {...register('phoneNumber')} />
                                                    <FormError errors={errors} field={'phoneNumber'} />
                                                </div>
                                            </div>
                                        </div>



                                        <div className="text-center">
                                            <button className="btn btn-primary btn11" type='submit' disabled={isLoading}>
                                                <span>{isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}</span>
                                            </button> &nbsp;
                                            <button className="btn btn-danger btn11" type='button' onClick={handleCancel}>
                                                <span>Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminProfile;
import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Editor from 'react-simple-wysiwyg';
import { PinAddEditFormValidate } from '../../../resources/form-validator'
import { PinFormI } from '../../../resources/form-props'
import FormError from '../../../components/FormError';
import { useCategoryApi } from '../../../resources/hooks/api/categoryApiHook'
import { usePinApi } from '../../../resources/hooks/api/pinApiHook'
import { useUserApi } from '../../../resources/hooks/api/userApiHook'
import DropZone from '../../../components/DropZone';
import { PinImageType } from '../../../resources/constants'
import { getUser } from '../../../store/user/selectors'
import { USER_ROLE } from '../../../resources/constants';
import { Spinner } from 'react-bootstrap';

interface Props {
    pinDetails?: any | null,
    handleCancel: () => void
}
const AddEditForm: React.FC<Props> = ({ pinDetails = null, handleCancel }) => {

    const loggedInUser = useSelector(getUser);
    const { list: fetchCategory } = useCategoryApi();
    const { add: addPin, edit: editPin } = usePinApi();
    const { list: providerListCall } = useUserApi();
    const [categories, setCategories] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any[]>([]);
    const [pinFile, setPinFile] = useState<string | null>(null);
    const [selectedCatIds, setSelectedCatIds] = useState<number[]>([]);

    useEffect(() => {
        callListCategory();
    }, [])
    useEffect(() => {
        if (pinDetails) {
            const ids: number[] = pinDetails && pinDetails['objPinsCategory'].map((cat: any) => {
                const subCats = cat['objSubcategories'].map((subCat: any) => subCat['subCategoryId'])
                return [cat['categoryId'], ...subCats]
            }).reduce((a: any[], c: any) => {
                a = [...a, ...c];
                return a;
            }, [])
            setSelectedCatIds(ids);

            if (pinDetails['pinImage']) {
                setPinFile(pinDetails['pinImage'])
            }

            reset({
                User: pinDetails['roleId'] != USER_ROLE.ADMIN ? {
                    label: pinDetails['firstName'] + ' ' + pinDetails['lastName'],
                    value: pinDetails['userId']
                } : {},
                PinTitle: pinDetails['pinTitle'],
                CurrentState: pinDetails['currentState'],
                ProblemStatement: pinDetails['problemStatement'],
                DesiredOutcome: pinDetails['desiredOutcome'],
                Solutions: pinDetails['solutions'],
                Image: null,
                PinCategories: []
            })
        }
    }, [pinDetails])
    useEffect(() => {
        if (selectedFile.length) {
            clearErrors('Image')
            setPinFile(null)
        }
    }, [selectedFile])

    const callListCategory = () => {
        const params = {
            roleId: 1
        }
        setIsLoading(true);
        fetchCategory(params, (message: string, resp: any) => {
            setIsLoading(false);
            setCategories(resp['data'])
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    const { register, control, reset, handleSubmit, getValues, setError, clearErrors, formState: { errors } } = useForm<PinFormI>({
        defaultValues: {
            User: null,
            PinTitle: '',
            CurrentState: '',
            ProblemStatement: '',
            DesiredOutcome: '',
            Solutions: '',
            Image: null,
            PinCategories: []
        },
        resolver: yupResolver(PinAddEditFormValidate)
    });

    const loadSolutionProviderOptions = (inputValue: string) => {
        return new Promise<any[]>(async (resolve) => {
            fetchSolutionProviders(inputValue).then((result: any) => {
                resolve(result)
            })
        })
    };
    const fetchSolutionProviders = (searchText: string) => {
        return new Promise<any[]>(async (resolve) => {
            const params = {
                UserType: USER_ROLE.SOLUTION_PROVIDER,
                PageNumber: 1,
                PageSize: 10,
                SearchText: searchText
            }
            providerListCall(params, (message: string, resp: any) => {
                const data = resp['data']['objListUser'].map((provider: any) => ({
                    label: provider['firstName'] + ' ' + provider['lastName'],
                    value: provider['userProfileId']
                }));
                resolve(data);
            }, (message: string, resp: any) => {
                resolve([]);
                toast.error(message)
            })
        });
    };

    const onSubmit = (data: any) => {

        if (!selectedFile.length && !pinFile) {
            setError("Image", { message: 'Please upload pin image' })
            toast.error('Please upload pin image')
            return;
        }

        var categories = data.PinCategories
            .map((e: string) => e.split('_'))
            .reduce((a: number[], c: string) => {
                if (!a.includes(+c[0])) {
                    a.push(+c[0])
                }
                if (!a.includes(+c[1])) {
                    a.push(+c[1])
                }
                return a;
            }, [])

        const formData: any = new FormData();
        formData.append("UserId", data.User && data.User.value || loggedInUser.userId || null)
        formData.append("PinTitle", data.PinTitle);
        formData.append("CurrentState", data.CurrentState);
        formData.append("ProblemStatement", data.ProblemStatement);
        formData.append("DesiredOutcome", data.DesiredOutcome);
        formData.append("Solutions", data.Solutions);
        formData.append("Image", selectedFile && selectedFile.length && selectedFile[0] || null);
        formData.append("PinCategories", categories);
        formData.append("CreatedBy", loggedInUser.userId);

        setIsLoading(true);
        if (pinDetails) {
            formData.append('PinsId', pinDetails.pinsId)

            editPin(formData, (message: string, resp: any) => {
                setIsLoading(false);
                resetForm()
                handleCancel();
                toast.success(message)
            }, (message: string, resp: any) => {
                setIsLoading(false);
                handleCancel();
                toast.error(message)
            })
        } else {
            addPin(formData, (message: string, resp: any) => {
                setIsLoading(false);
                resetForm()
                handleCancel();
                toast.success(message)
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message)
            })
        }
    }

    const resetForm = () => {
        reset();
        setSelectedFile([]);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {/* {console.log('errors: ', errors)} */}
            <div className="profile_page">
                <div className="profile_laft upload_product">
                    <DropZone
                        multiple={false}
                        className=""
                        setFiles={setSelectedFile}
                        files={pinFile ? [pinFile] : selectedFile}
                        acceptedFileTypes={PinImageType}
                        maxFiles={1}
                        maxSize={4e+8} // 40 MB
                    />
                    <FormError errors={errors} field={'Image'} />
                </div>

                <div className="profile_right">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3>{pinDetails && 'Update A Test Flight' || 'Add A Test Flight'}</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="solutionProviders" className="form-label">
                                    Solution Providers
                                </label>
                                <Controller
                                    name={"User"}
                                    control={control}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <AsyncSelect
                                            className={`mt-1`}
                                            cacheOptions
                                            loadOptions={loadSolutionProviderOptions}
                                            placeholder={'Select Solution Provider'}
                                            value={value}
                                            defaultValue={value}
                                            onChange={onChange}
                                            defaultOptions />
                                    )}
                                />
                                <FormError errors={errors} field={'User'} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Test Flight Title<strong className='text-danger'>*</strong></label>
                                <input type="text" {...register('PinTitle')} className="form-control" id="exampleFormControlInput1"
                                    placeholder="Test Flight title" maxLength={250} />
                                <FormError errors={errors} field={'PinTitle'} className='iuiu' />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Current State<strong className='text-danger'>*</strong></label>
                                <textarea className="form-control" {...register('CurrentState')} maxLength={2000}></textarea>
                                <FormError errors={errors} field={'CurrentState'} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Problem Statement</label>
                                <textarea className="form-control" {...register('ProblemStatement')} maxLength={2000}></textarea>
                                <FormError errors={errors} field={'ProblemStatement'} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput3" className="form-label">Desired Outcome</label>
                                <textarea className="form-control" {...register('DesiredOutcome')} maxLength={2000}></textarea>
                                <FormError errors={errors} field={'ProblemStatement'} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput3" className="form-label">Solutions</label>
                                <Controller
                                    defaultValue=""
                                    control={control}
                                    name="Solutions"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Editor value={value} onChange={onChange} />
                                    )}
                                />


                                {/* <Controller
                                    name="Solutions"
                                    control={control}
                                    defaultValue="" // Initial content value
                                    render={({ field }) => (
                                        <Editor
                                            apiKey={process.env.TINYMCE_API_KEY}
                                            value={field.value}
                                            plugins= {[
                                                'image',
                                                'advlist',
                                                'autolink',
                                                'lists',
                                                'link',
                                                'image',
                                                'charmap',
                                                'preview',
                                                'anchor',
                                                'searchreplace',
                                                'visualblocks',
                                                'code',
                                                'fullscreen',
                                                'insertdatetime',
                                                'media',
                                                'table',
                                                'code',
                                                'help',
                                                'wordcount',
                                                'anchor',
                                            ]}
                                            toolbar=
                                                {'undo redo | blocks | image | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |removeformat | help'}
                                            onEditorChange={(content) => field.onChange(content)}
                                        />
                                    )}
                                /> */}
                                <FormError errors={errors} field={'Solutions'} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput3" className="form-label">Test Flight Tags</label>
                                <div className="stepcategory_list">
                                    {
                                        categories.map((category: any, index: number) => (
                                            <div className="category_list" key={index}>
                                                <h5>{category?.categoryName}</h5>
                                                {
                                                    category['subCategories'].map((subCategory: any, rowNumber: number) => (
                                                        <div className="form-check" key={rowNumber}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={category['categoryId'] + '_' + subCategory['subCategoryId']}
                                                                id={`subcategoryCheck_${index}_${rowNumber}`}
                                                                {...register('PinCategories')}
                                                                {...(selectedCatIds.includes(subCategory.subCategoryId) ? { defaultChecked: true } : {})}
                                                            />
                                                            <label className="form-check-label" htmlFor={`subcategoryCheck_${index}_${rowNumber}`}>
                                                                {subCategory?.subCategoryName}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                </div>


                            </div>
                        </div>

                    </div>

                    <div className="text-center">
                        <button className="btn btn-primary btn11" type='submit' disabled={isLoading}>
                            <span>{
                                isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'
                            }</span>
                        </button> &nbsp;
                        <button className="btn btn-danger btn11" type='button' onClick={handleCancel}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AddEditForm;
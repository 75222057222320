import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { usePinApi } from '../../../resources/hooks/api/pinApiHook'
import AddEditForm from './AddEditForm';
import { useAppLoader } from '../../../resources/hooks/common/appLoaderHook';
import { URLS } from '../../../resources/constants';
import TitleBar from '../../../components/TitleBar';

interface Props {

}

const PinEdit: React.FC<Props> = () => {

    const { id: pinId } = useParams();
    const { showLoader, hideLoader } = useAppLoader();
    const { fetchSingle: fetchSinglePin, edit: pinEdit } = usePinApi();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pinDetails, setPinDetails] = useState<any | null>(null);

    useEffect(() => {
        handleFetchPin();
    }, [pinId])


    const handleFetchPin = () => {
        const params = {
            pinId: pinId
        }
        showLoader();
        fetchSinglePin(params, (message: string, resp: any) => {
            hideLoader();
            if (resp['data'] && resp['data']['objListPin'] && resp['data']['objListPin'].length) {
                setPinDetails(resp['data']['objListPin'][0]);
            } else {
                navigate(URLS.PINS.LIST)
                toast.error('Test Flight details not found')
            }
        }, (message: string, resp: any) => {
            hideLoader();
            navigate(URLS.PINS.LIST)
            toast.error(message)
        })
    }

    const handleCancel = () => {
        navigate(URLS.PINS.LIST)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <TitleBar title={'Update A Test Flight'} breadcrumbs={{
                    'Test Flight': URLS.PINS.LIST,
                    'Update': '#',
                }} />

                <div className="row">
                    <div className="col-12">
                        <div className="">
                            {
                                pinDetails &&
                                <AddEditForm handleCancel={handleCancel} pinDetails={pinDetails} /> || <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PinEdit;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoryI } from './types'

export const initialState: CategoryI = {
  details: {},
  list: []
}

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    updateList(state: CategoryI, action: PayloadAction<any>) {
      state.list = action.payload
    },
    updateListCategory(state: CategoryI, action: PayloadAction<any>) {
      const categories = state.list;
      const gotIndex = categories.findIndex((e : any, index: number) => e['id'] == action.payload.id);

      if ( gotIndex < 0 ) {
        
      } else {
        const data = categories[gotIndex];
        state.list = categories.splice(gotIndex, 1, data);
      }
    },
  },
})

export const { reducer } = categorySlice

export const { updateList, updateListCategory } = categorySlice.actions

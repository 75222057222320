import { combineReducers } from 'redux'
import { reducer as user } from './user'
import { reducer as solutionProvider } from './solutionProvider'
import { reducer as pin } from './pin'
import { reducer as category } from './category'
import { reducer as appLoader } from './appLoader'

import { reducer as settings } from './settings'
import { reducer as tableData } from './tableData'

const reducers = {
  user,
  solutionProvider,
  pin,
  category,
  appLoader,
  settings,
  tableData
}

export const appReducer = combineReducers(reducers)

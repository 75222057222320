
import { call, put } from "redux-saga/effects";
import { updateSettings } from "../store/settings";
import { API_URLS } from "../resources/constants";
import {CallApi} from './callApi'

export function* fetchSettings(action: any): any {
  try {
    let result = yield call(CallApi.GET, API_URLS.SETTINGS);
    yield put(updateSettings(result.data));
  } catch (e) {
    action && action.callbackError(e)
  }
}



import React from 'react';
import { Link } from 'react-router-dom';

interface Props {}

const NotFoundPage: React.FC<Props> = () => {
        return <div>
            <img src={'/assets/images/404.png'} height={'100%'} width={'100%'} />
            <p style={{textAlign:"center"}}>
              <Link to="/">Go to Home </Link>
            </p>
          </div>;
}
export default NotFoundPage;
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-hot-toast';
import { usePinApi } from '../../../resources/hooks/api/pinApiHook';
import { showDate } from '../../../resources/utils/functions';
import { getPinStatus } from '../../../resources/utils/functionsStatic';

interface IncomingProps {
    show: boolean;
    close: () => void;
    pinDetails: any;
}

const ReviewListModal = ({ show, close, pinDetails }: IncomingProps) => {
    const { getPinReview } = usePinApi();

    const [loading, setLoading] = useState<boolean>(false)
    const [reviewList, setReviewList] = useState<any>([])

    useEffect(() => {
        handleFetchPin();
    }, [pinDetails])


    const handleFetchPin = () => {
        const params = {
            PinsId: pinDetails?.pinsId
        }
        setLoading(true);
        getPinReview(params, (message: string, resp: any) => {
            setLoading(false);
            if (resp['data'] && resp['data'].length) {
                setReviewList(resp['data']);
            }
        }, (message: string, resp: any) => {
            setLoading(false);
            toast.error(message)
        })
    }


    return (
        <React.Fragment>
            <Modal
                className={'salesforce-modal modal-lndscp '}
                show={show}
            // onHide={() => { close(); reset() }}
            >
                <div className="modal-header custom-modal-header">
                    <div className="d-flex flex-wrap">
                        <h5 className="card-title mr-2">Review Logs</h5>
                    </div>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => { close(); }}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <Modal.Body>
                    {loading ?
                        <div>
                            Loading......
                        </div>
                        :
                        <div className="row">
                            <table className='table'>

                                <tbody>
                                    {
                                        reviewList && reviewList?.length == 0 ?
                                            <tr>
                                                <td  >No data found!</td>
                                            </tr>
                                            :
                                            <>
                                                {
                                                    reviewList.map((item: any, index: any) =>
                                                        <tr>
                                                            <td>{item?.comment}

                                                                <div className='text-right'> 

                                                                    -  <b>{getPinStatus(item?.reviewStatus)}</b> by &nbsp;
                                                                    {item?.firstName} {item?.lastName}  on  &nbsp;
                                                                    {showDate(item?.reviewDate)}
                                                                </div>
                                                            </td>


                                                        </tr>
                                                    )
                                                }
                                            </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </Modal.Body>

                <hr />
                <div style={{ textAlign: 'center' }}>
                    <button className="btn btn-danger" onClick={() => { close(); }}>
                        Close
                    </button>
                </div>
                <br />

            </Modal>
        </React.Fragment>
    )
}

export default ReviewListModal
import { useDispatch } from 'react-redux'
import { updateLoader } from '../../../store/appLoader/index'
import { AppLoaderI } from '../../../store/appLoader/types'

export function useAppLoader() {
    const dispatch = useDispatch();
    const show = (message: string = 'Please wait') => {
        const params: AppLoaderI = {
            isLoading: true,
            message: message
        }
        dispatch(updateLoader(params))
    }
    const hide = () => {
        const params: AppLoaderI = {
            isLoading: false,
            message: ''
        }
        dispatch(updateLoader(params))
    }
    return { showLoader: show, hideLoader: hide }
}
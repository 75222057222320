import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { getIsLoading } from '../../store/appLoader/selectors';

import './index.css'

const FullPageLoader = () => {
  const isLoading = useSelector(getIsLoading);
  return (
    <>
      {
        isLoading && (
          <div className="rf-full-page-loader d-flex align-items-center justify-content-center">
            <div className="rf-loader-container">
              <img src="assets/images/loader.svg" alt="Loading..." width={120} />
            </div>
          </div>
        ) || <></>
      }
    </>
  );
};

export default FullPageLoader;

import React, { ReactNode } from 'react'

import CustomModal from '../CustomModal'

interface Props {
    title: string,
    content: string,
    isShow: boolean,
    handleClose: () => void,
    footerButtonYes: boolean,
    footerButtonYesText: string,
    footerButtonYesAction: () => void,
    footerButtonYesClass: string,
    footerButtonNo: boolean,
    footerButtonNoText: string,
    footerButtonNoAction: () => void,
    footerButtonNoClass: string,
    showLoading?: boolean
}
const CustomAlert: React.FC<Props> = ({ 
    title, 
    content, 
    isShow, 
    handleClose,
    footerButtonYes = false,
    footerButtonYesText = '',
    footerButtonYesAction = () => {},
    footerButtonYesClass = '',
    footerButtonNo = false,
    footerButtonNoText = '',
    footerButtonNoAction = () => {},
    footerButtonNoClass = '',
    showLoading = false
}) => {
    const theObj = {__html:content};

    return (
        <CustomModal 
            showFooter={true} 
            isShow={isShow} 
            handleClose={handleClose} 
            title={title}
            footerButtonYes={footerButtonYes}
            footerButtonYesText={footerButtonYesText}
            footerButtonYesAction={footerButtonYesAction}
            footerButtonYesClass={footerButtonYesClass}
            footerButtonNo={footerButtonNo}
            footerButtonNoText={footerButtonNoText}
            footerButtonNoAction={footerButtonNoAction}
            footerButtonNoClass={footerButtonNoClass}
            showLoading={showLoading}
        >
            <div className="alert alert-danger" role="alert">
                <div dangerouslySetInnerHTML={theObj} />
            </div>
            
        </CustomModal>
    )
}

export default CustomAlert;
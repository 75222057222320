import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

import { useSelector } from 'react-redux';
import { getUser, getAuthenticated } from '../../../store/user/selectors'

import { useUserApi } from '../../../resources/hooks/api/userApiHook'

import UserProfile from './UserProfile';
import MyExpertise from './MyExpertise';
import UserPin from './UserPin';

interface Props {

}

const UtilityUserDetails: React.FC<Props> = () => {
    
    const { fetchProfile } = useUserApi();
    const navigate = useNavigate();
    const { id: profileId } = useParams();
    const [selectedTab, setSelectedTab] = useState<string>('user_info')    
    const [profileDetails, setProfileDetails] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = () => {
        const params = {
            roleId: 1,
            userProfileId: profileId
        }
        setIsLoading( true );
        fetchProfile(params, (message: string, resp: any) => {
            setIsLoading( false );
            if ( resp['data'] ) {
                setProfileDetails(resp['data']);
            } else {
                navigate('/utility-user')
            }
        }, (message: string, resp: any) => {
            setIsLoading( false );
            toast.error(message)
            navigate('/utility-user')
        })
    }

    return (
        <div className="page-content">
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">
                                {
                                    selectedTab == 'user_info' ? 'User Info' :
                                    selectedTab == 'my_expertise' ? 'My Expertise' :
                                    'Pin'
                                }
                            </h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Dashboard</Link> 
                                    </li>
                                    <li className="breadcrumb-item active">
                                    {
                                        selectedTab == 'user_info' ? 'User Info' :
                                        selectedTab == 'my_expertise' ? 'My Expertise' :
                                        'Pin'
                                    }
                                    </li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>

                
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <div className="profile_page">
                                <div className="profile_laft">
                                    <div className="profile_pic">
                                        {
                                            profileDetails && profileDetails['profilePic'] && <img src={profileDetails['profilePic']} /> || <img src={`${process.env.PUBLIC_URL}/assets/images/no-image.jpg`} /> 
                                        }
                                    </div>
                                    <h3>{profileDetails?.firstName +" "+ profileDetails?.lastName}</h3>
                                    {/* <p>New York, USA</p> */}
                                    <div className="prof_menue">
                                        <ul>
                                        <li>
                                            <a href="javascript:void(0)" onClick={() => setSelectedTab('user_info')} className={selectedTab == 'user_info' ? "active" : ''}>
                                                <i className="dripicons-user"></i> User Info
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={() => setSelectedTab('my_expertise')} className={selectedTab == 'my_expertise' ? "active" : ''}>
                                                <i className="dripicons-briefcase"></i> My Expertise
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" onClick={() => setSelectedTab('user_pin')} className={selectedTab == 'user_pin' ? "active" : ''}>
                                                <i className="dripicons-pin"></i> Test Flight
                                            </a>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                {
                                    selectedTab == 'user_info' ? <UserProfile /> :
                                    selectedTab == 'my_expertise' ? <MyExpertise /> : <UserPin />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default UtilityUserDetails;
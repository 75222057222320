import React from 'react'
import { RouterProvider } from 'react-router-dom'
import './app.scss';

import Routes from './router'


const App: React.FC = () => (
  <RouterProvider router={Routes} fallbackElement={<p>Initial Load...</p>} />
)

export default App


import React from "react";
import Form from 'react-bootstrap/Form';

interface Props {
    className?: string,
    field: string,
    errors: any
}
const FormError: React.FC<Props> = ({ className='', field, errors }) => {
    // console.log('error: ', errors, ' param: ', field)
    return (
        <>
        {
            errors && errors[field] && errors[field].message && (
                <Form.Text className="text-danger">
                    {errors[field].message}
                </Form.Text>
            ) || ''
        }
        </>
    )
}

export default FormError;
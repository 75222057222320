
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import toast from 'react-hot-toast';

import { useUserApi } from '../../../resources/hooks/api/userApiHook';

interface Props {

}
const MyExpertise: React.FC<Props> = () => {

    const { id: profileId } = useParams();
    const { fetchCategoryMap } = useUserApi();
    const [profileExpertises, setProfileExpertises] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getProfileExpertise();
    }, [])

    const getProfileExpertise = () => {
        const params = {
            roleId: 1,
            userProfileId: profileId
        }
        setIsLoading(true);
        fetchCategoryMap(params, (message: string, resp: any) => {
            setIsLoading(false);
            setProfileExpertises(resp['data']['objCategories']);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message)
        })
    }

    return (
        <div className="profile_right step_page">
            <div className="d-flex align-items-center justify-content-between">
                <h3>my EXPERTISE</h3>
            </div>

            <div className="stepcategory_list">
                {
                    profileExpertises.map((category: any, index: number) => (
                        <div className="category_list" key={index}>
                            <h5>{category?.categoryName}</h5>
                            {
                                category['subCategories'].map((subCategory: any, rowNumber: number) => (
                                    <div className="form-check" key={rowNumber}>
                                        <label className="form-check-label" htmlFor={"flexCheck" + rowNumber}>
                                            {subCategory?.subCategoryName}
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MyExpertise;
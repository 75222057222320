
import * as yup from "yup";
import { RegExp } from '../resources/constants'

export const LoginFormValidate = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Username required'),
    password: yup.string().required('Password required')
})

export const ForgotPasswordFormValidate = yup.object().shape({
    email: yup.string().email().required('Email required')
})

export const ForgotPasswordResetFormValidate = yup.object().shape({
  password: yup.string()
    .required("Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters"),
  confirmPassword: yup.string()
    .required("Confirm Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters")
    .oneOf([yup.ref("password")], "Passwords do not match")
})

export const CategoryAddEditFormValidate = yup.object().shape({
    categoryName: yup.string().required('Name required')
})

export const SolutionProviderFormValidate = yup.object().shape({
    firstName: yup.string().required('First name required'),
    lastName: yup.string().required('Last name required'),
    phoneNumber: yup.string().test( `test-phone-length`, function ( value ) {
        const { path, createError } = this;
        const formatted = value && value.replace( /\D+/g, '' ) || '';
        return (
            !formatted || ( formatted && formatted.length === 10 ) ||
            createError( { path, message: "Invalid phone number" } )
        );
    }), //.matches(RegExp.phoneRegExp, 'Phone number is not valid'),
    email: yup.string().email('Invalid email address').required('Email required'),
    jobTitle: yup.string().required('Job Title required'),
    companyName: yup.string().required('Company name required'),

})

export const PinAddEditFormValidate = yup.object().shape({
    User: yup.object()
    .shape({
      label: yup.string(),
      value: yup.number(),
    }).nullable(),
    PinTitle: yup.string().required('Title required'),
    CurrentState: yup.string().required('State required'),
    ProblemStatement: yup.string(),
    DesiredOutcome: yup.string(),
    Solutions: yup.string(),
    Image: yup.mixed()
    .test("fileSize", "File Size is too large", (value) => {
        return true;
        //value && value.length && value[0].size <= 4e+8 /* 40MB */
    })
    .test("fileType", "Unsupported File Format", (value) => {
        return true;
      // console.log('value: ', value, ' ', ProductFileAcceptTypes.includes(value[0].type))
      /*const model3D = ['gltf', 'glb']
      if (value && value.length && !value[0].type) {
        const ext = value[0].name.split('.').pop()
        return model3D.includes(ext)
      } else {
        return value && value.length && ProductFileAcceptTypes.includes(value[0].type)
      }*/
    })
    .nullable(),
    PinCategories: yup.array()//.of(yup.object()),
})
/* 
    this component use for breadcrumps and page title 
*/

import React, { HtmlHTMLAttributes } from 'react'
import { Link } from 'react-router-dom';
import { URLS } from '../resources/constants';


interface props extends HtmlHTMLAttributes<HTMLDivElement> {
    title: any;
    breadcrumbs?: any
    dashboard?: boolean
}
const TitleBar: React.FC<props> = ({
    children,
    title,
    breadcrumbs,
    dashboard = true,
    ...args
}) => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">{title}</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><Link to={URLS.DASHBOARD}>Dashboard</Link></li>


                            {
                                breadcrumbs && Object.keys(breadcrumbs).map((key: any, index: any) => (
                                    <li key={`bed-${index}`} className="breadcrumb-item active">
                                        {(breadcrumbs[key] == '#') ? key : <Link to={breadcrumbs[key]}>{key}</Link>}
                                    </li>
                                ))
                            }
                        </ol>
                    </div>

                </div>
            </div>
        </div>




    )
}

export default TitleBar;

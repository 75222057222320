import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useUserApi() {

    const callApi = useApiCall();

    const login = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
    }
    const forgetPassword = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.FORGET_PASSWORD, data, onSuccess, onError);
    }
    const fetchUserById = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.FETCH_USER_BY_EMAIL, data, onSuccess, onError);
    }
    const forgetPasswordReset = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.FORGET_PASSWORD_RESET, data, onSuccess, onError);
    }

    const add = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.ADD, data, onSuccess, onError);
    }
    const addProfile = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.ADD_PROFILE, data, onSuccess, onError);
    }
    const edit = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.EDIT, data, onSuccess, onError);
    }
    const userApproval = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.APPROVAL, data, onSuccess, onError);
    }
    const remove = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.DELETE, data, onSuccess, onError);
    }
    const list = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.LIST, data, onSuccess, onError);
    }
    const fetchProfile = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE, data, onSuccess, onError);
    }
    const fetchCategoryMap = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SOLUTION_PROVIDER.FETCH_PROFILE_CATEGORY_MAP, data, onSuccess, onError);
    }

    const resendEmailVerification = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.USER.RESEND_EMAIL_VERIFICATION, data, onSuccess, onError);
    }


    return {
        login,
        forgetPassword,
        fetchUserById,
        forgetPasswordReset,

        add,
        addProfile,
        edit,
        userApproval,
        remove,
        list,
        fetchProfile,
        fetchCategoryMap,

        resendEmailVerification,


    }
}